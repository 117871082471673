@import "../../styles/variables.css";

.Map {
  width: 100%;
  height: 100%;
}

.clusterIcon {
  background: var(--color-blue-dark);
  color: var(--color-white);
  border-radius: 100%;
  border: 2px solid var(--color-white);
  font-family: var(--font-family-gotham);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  box-shadow: var(--box-shadow);
}

.markerLabel {
  //background-color: var(--color-blue-dark);
  color: var(--color-white) !important;
  padding: 16px 12px 0 12px;
  //transform: translateY(15px);
  display: inline-block;
  max-width: 100px;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 30px;
  //border-radius: 15px;
  font-family: var(--font-family-lato) !important;
  font-size: .7rem !important;
  font-weight: var(--font-weight-regular);
  line-height: 0;
  //border: var(--border-light);
  //border-color: white;
  //border-width: 2px;
}

.loader {
  width: 100%;
  height: 100%;
  padding-bottom: 60%;
  position: relative;
  background-color: var(--color-gray-light);
}
