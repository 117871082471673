@import "../../styles/variables.css";

.Story {
  overflow: hidden;
  padding: var(--padding-section-xs) 0;

  @media (--mq-sm) {
    padding: var(--padding-section-md) 0;
  }
}

.media {
  border-radius: var(--border-radius);
  margin-bottom: 2rem;

  @media (--mq-sm) {
    margin-bottom: 0;
  }
}

.map {
  height: 0;
  padding-bottom: 65%;
}

.content {
  > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
