@import "../../styles/variables.css";

.DotNavigation {
  height: 13px;
}

.dot {
  position: relative;
  width: 13px;
  height: 13px;
  cursor: pointer;
  transition: transform .1s;

  &:before {
    position: absolute;
    content: '';
    top: 3px;
    left: 3px;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: currentColor;
    box-shadow: var(--box-shadow);
    opacity: .5;
  }

  &:hover {
    transform: scale(1.5);
  }
}

.dotActive {
  composes: dot;

  &:before {
    opacity: 1;
  }
}
