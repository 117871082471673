@import "../../styles/variables.css";

.PlatformFeatured {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
  background-color: var(--color-white);
  color: inherit;
  border: var(--border-dark);
  border-radius: var(--border-radius);
  margin-bottom: var(--grid-gutter-2);
  height: calc(100% - var(--grid-gutter-2));

  &:hover {
    text-decoration: none;
  }
}

.logo {
  height: 5rem;
  width: 50%;
  object-fit: contain;
  object-position: left top;
}

.tagLine {
  margin-top: 2rem;
  color: inherit;
}
