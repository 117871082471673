
/* ADD XXL */
@media only screen and (min-width: 120em) {
  .container {
    width: 121rem;
  }

  .col-xxl,
  .col-xxl-1,
  .col-xxl-2,
  .col-xxl-3,
  .col-xxl-4,
  .col-xxl-5,
  .col-xxl-6,
  .col-xxl-7,
  .col-xxl-8,
  .col-xxl-9,
  .col-xxl-10,
  .col-xxl-11,
  .col-xxl-12,
  .col-xxl-offset-0,
  .col-xxl-offset-1,
  .col-xxl-offset-2,
  .col-xxl-offset-3,
  .col-xxl-offset-4,
  .col-xxl-offset-5,
  .col-xxl-offset-6,
  .col-xxl-offset-7,
  .col-xxl-offset-8,
  .col-xxl-offset-9,
  .col-xxl-offset-10,
  .col-xxl-offset-11,
  .col-xxl-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-xxl {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-xxl-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xxl-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xxl-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xxl-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xxl-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xxl-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xxl-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xxl-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xxl-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-xxl-offset-0 {
    margin-left: 0;
  }

  .col-xxl-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-xxl-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-xxl-offset-3 {
    margin-left: 25%;
  }

  .col-xxl-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-xxl-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-xxl-offset-6 {
    margin-left: 50%;
  }

  .col-xxl-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-xxl-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-xxl-offset-9 {
    margin-left: 75%;
  }

  .col-xxl-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-xxl-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-xl {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-xl {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }

  .end-xl {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-xl {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .middle-xl {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .bottom-xl {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .around-xl {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .between-xl {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .first-xl {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .last-xl {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
