@import "variables.css";

html, body {
  padding: 0;
  margin: 0;
}

*, *:before, *:after {
  box-sizing: border-box;
}

main {
  position: relative;
  min-height: calc(7 * var(--grid-row));
}

img {
  display: block;
  border: 0;
  width: 100%;
  max-width: 100%;
  height: auto;
}

figcaption {
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-medium);
  margin: 0 0 90px 0;
  max-width: 75%;

  @media (--mq-sm) {
    max-width: 100%;
  }
}
