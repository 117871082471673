@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@font-face {
  font-family: 'Gotham';
  src: url("assets/fonts/Gotham-Book.woff2") format("woff2"),
  url("assets/fonts/Gotham-Book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url("assets/fonts/Gotham-Medium.woff2") format("woff2"),
  url("assets/fonts/Gotham-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url("assets/fonts/Gotham-Bold.woff2") format("woff2"),
  url("assets/fonts/Gotham-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

