@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap);
/* Define Custom Media queries *//* Define global CSS variables inside :root */:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}/* Uncomment and set these variables to customize the grid. */.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
}.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}.row.reverse {
  flex-direction: row-reverse;
}.col.reverse {
  flex-direction: column-reverse;
}.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 1rem;
  padding-left: 1rem;
}.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}.col-xs-1 {
  flex-basis: 8.333%;
  max-width: 8.333%;
}.col-xs-2 {
  flex-basis: 16.667%;
  max-width: 16.667%;
}.col-xs-3 {
  flex-basis: 25%;
  max-width: 25%;
}.col-xs-4 {
  flex-basis: 33.333%;
  max-width: 33.333%;
}.col-xs-5 {
  flex-basis: 41.667%;
  max-width: 41.667%;
}.col-xs-6 {
  flex-basis: 50%;
  max-width: 50%;
}.col-xs-7 {
  flex-basis: 58.333%;
  max-width: 58.333%;
}.col-xs-8 {
  flex-basis: 66.667%;
  max-width: 66.667%;
}.col-xs-9 {
  flex-basis: 75%;
  max-width: 75%;
}.col-xs-10 {
  flex-basis: 83.333%;
  max-width: 83.333%;
}.col-xs-11 {
  flex-basis: 91.667%;
  max-width: 91.667%;
}.col-xs-12 {
  flex-basis: 100%;
  max-width: 100%;
}.col-xs-offset-1 {
  margin-left: 8.333%;
}.col-xs-offset-2 {
  margin-left: 16.667%;
}.col-xs-offset-3 {
  margin-left: 25%;
}.col-xs-offset-4 {
  margin-left: 33.333%;
}.col-xs-offset-5 {
  margin-left: 41.667%;
}.col-xs-offset-6 {
  margin-left: 50%;
}.col-xs-offset-7 {
  margin-left: 58.333%;
}.col-xs-offset-8 {
  margin-left: 66.667%;
}.col-xs-offset-9 {
  margin-left: 75%;
}.col-xs-offset-10 {
  margin-left: 83.333%;
}.col-xs-offset-11 {
  margin-left: 91.667%;
}[dir="ltr"] .start-xs{
  text-align: left;
}[dir="rtl"] .start-xs{
  text-align: right;
}.start-xs {
  justify-content: flex-start;
}.center-xs {
  justify-content: center;
  text-align: center;
}[dir="ltr"] .end-xs{
  text-align: right;
}[dir="rtl"] .end-xs{
  text-align: left;
}.end-xs {
  justify-content: flex-end;
}.top-xs {
  align-items: flex-start;
}.middle-xs {
  align-items: center;
}.bottom-xs {
  align-items: flex-end;
}.around-xs {
  justify-content: space-around;
}.between-xs {
  justify-content: space-between;
}.first-xs {
  order: -1;
}.last-xs {
  order: 1;
}@media only screen and (min-width: 48em) {
  .container {
    width: 46rem;
  }

  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-sm-1 {
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-sm-2 {
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-sm-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-sm-5 {
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-sm-8 {
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-sm-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-sm-11 {
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-sm-offset-1 {
    margin-left: 8.333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.667%;
  }

  [dir="ltr"] .start-sm{
    text-align: left;
  }

  [dir="rtl"] .start-sm{
    text-align: right;
  }

  .start-sm {
    justify-content: flex-start;
  }

  .center-sm {
    justify-content: center;
    text-align: center;
  }

  [dir="ltr"] .end-sm{
    text-align: right;
  }

  [dir="rtl"] .end-sm{
    text-align: left;
  }

  .end-sm {
    justify-content: flex-end;
  }

  .top-sm {
    align-items: flex-start;
  }

  .middle-sm {
    align-items: center;
  }

  .bottom-sm {
    align-items: flex-end;
  }

  .around-sm {
    justify-content: space-around;
  }

  .between-sm {
    justify-content: space-between;
  }

  .first-sm {
    order: -1;
  }

  .last-sm {
    order: 1;
  }
}@media only screen and (min-width: 62em) {
  .container {
    width: 61rem;
  }

  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-md-1 {
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-md-2 {
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-md-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-md-5 {
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-md-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-md-8 {
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-md-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-md-11 {
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-md-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-md-offset-1 {
    margin-left: 8.333%;
  }

  .col-md-offset-2 {
    margin-left: 16.667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.333%;
  }

  .col-md-offset-5 {
    margin-left: 41.667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.333%;
  }

  .col-md-offset-8 {
    margin-left: 66.667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.333%;
  }

  .col-md-offset-11 {
    margin-left: 91.667%;
  }

  [dir="ltr"] .start-md{
    text-align: left;
  }

  [dir="rtl"] .start-md{
    text-align: right;
  }

  .start-md {
    justify-content: flex-start;
  }

  .center-md {
    justify-content: center;
    text-align: center;
  }

  [dir="ltr"] .end-md{
    text-align: right;
  }

  [dir="rtl"] .end-md{
    text-align: left;
  }

  .end-md {
    justify-content: flex-end;
  }

  .top-md {
    align-items: flex-start;
  }

  .middle-md {
    align-items: center;
  }

  .bottom-md {
    align-items: flex-end;
  }

  .around-md {
    justify-content: space-around;
  }

  .between-md {
    justify-content: space-between;
  }

  .first-md {
    order: -1;
  }

  .last-md {
    order: 1;
  }
}@media only screen and (min-width: 75em) {
  .container {
    width: 71rem;
  }

  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-lg-1 {
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-lg-2 {
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-lg-5 {
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-lg-8 {
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-lg-11 {
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-lg-offset-1 {
    margin-left: 8.333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.667%;
  }

  [dir="ltr"] .start-lg{
    text-align: left;
  }

  [dir="rtl"] .start-lg{
    text-align: right;
  }

  .start-lg {
    justify-content: flex-start;
  }

  .center-lg {
    justify-content: center;
    text-align: center;
  }

  [dir="ltr"] .end-lg{
    text-align: right;
  }

  [dir="rtl"] .end-lg{
    text-align: left;
  }

  .end-lg {
    justify-content: flex-end;
  }

  .top-lg {
    align-items: flex-start;
  }

  .middle-lg {
    align-items: center;
  }

  .bottom-lg {
    align-items: flex-end;
  }

  .around-lg {
    justify-content: space-around;
  }

  .between-lg {
    justify-content: space-between;
  }

  .first-lg {
    order: -1;
  }

  .last-lg {
    order: 1;
  }
}/* ADD XL */@media only screen and (min-width: 90em) {
  .container {
    width: 91rem;
  }

  .col-xl,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-offset-0,
  .col-xl-offset-1,
  .col-xl-offset-2,
  .col-xl-offset-3,
  .col-xl-offset-4,
  .col-xl-offset-5,
  .col-xl-offset-6,
  .col-xl-offset-7,
  .col-xl-offset-8,
  .col-xl-offset-9,
  .col-xl-offset-10,
  .col-xl-offset-11,
  .col-xl-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-xl-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-xl-offset-0 {
    margin-left: 0;
  }

  .col-xl-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-xl-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-xl-offset-3 {
    margin-left: 25%;
  }

  .col-xl-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-xl-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-xl-offset-6 {
    margin-left: 50%;
  }

  .col-xl-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-xl-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-xl-offset-9 {
    margin-left: 75%;
  }

  .col-xl-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-xl-offset-11 {
    margin-left: 91.66666667%;
  }

  [dir="ltr"] .start-xl{
    text-align: left;
  }

  [dir="rtl"] .start-xl{
    text-align: right;
  }

  .start-xl {
    justify-content: flex-start;
  }

  .center-xl {
    justify-content: center;
    text-align: center;
  }

  [dir="ltr"] .end-xl{
    text-align: right;
  }

  [dir="rtl"] .end-xl{
    text-align: left;
  }

  .end-xl {
    justify-content: flex-end;
  }

  .top-xl {
    align-items: flex-start;
  }

  .middle-xl {
    align-items: center;
  }

  .bottom-xl {
    align-items: flex-end;
  }

  .around-xl {
    justify-content: space-around;
  }

  .between-xl {
    justify-content: space-between;
  }

  .first-xl {
    order: -1;
  }

  .last-xl {
    order: 1;
  }
}/* ADD XXL */@media only screen and (min-width: 120em) {
  .container {
    width: 121rem;
  }

  .col-xxl,
  .col-xxl-1,
  .col-xxl-2,
  .col-xxl-3,
  .col-xxl-4,
  .col-xxl-5,
  .col-xxl-6,
  .col-xxl-7,
  .col-xxl-8,
  .col-xxl-9,
  .col-xxl-10,
  .col-xxl-11,
  .col-xxl-12,
  .col-xxl-offset-0,
  .col-xxl-offset-1,
  .col-xxl-offset-2,
  .col-xxl-offset-3,
  .col-xxl-offset-4,
  .col-xxl-offset-5,
  .col-xxl-offset-6,
  .col-xxl-offset-7,
  .col-xxl-offset-8,
  .col-xxl-offset-9,
  .col-xxl-offset-10,
  .col-xxl-offset-11,
  .col-xxl-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-xxl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-xxl-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xxl-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xxl-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xxl-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xxl-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xxl-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xxl-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xxl-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xxl-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-xxl-offset-0 {
    margin-left: 0;
  }

  .col-xxl-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-xxl-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-xxl-offset-3 {
    margin-left: 25%;
  }

  .col-xxl-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-xxl-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-xxl-offset-6 {
    margin-left: 50%;
  }

  .col-xxl-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-xxl-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-xxl-offset-9 {
    margin-left: 75%;
  }

  .col-xxl-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-xxl-offset-11 {
    margin-left: 91.66666667%;
  }

  [dir="ltr"] .start-xl{
    text-align: left;
  }

  [dir="rtl"] .start-xl{
    text-align: right;
  }

  .start-xl {
    justify-content: flex-start;
  }

  .center-xl {
    justify-content: center;
    text-align: center;
  }

  [dir="ltr"] .end-xl{
    text-align: right;
  }

  [dir="rtl"] .end-xl{
    text-align: left;
  }

  .end-xl {
    justify-content: flex-end;
  }

  .top-xl {
    align-items: flex-start;
  }

  .middle-xl {
    align-items: center;
  }

  .bottom-xl {
    align-items: flex-end;
  }

  .around-xl {
    justify-content: space-around;
  }

  .between-xl {
    justify-content: space-between;
  }

  .first-xl {
    order: -1;
  }

  .last-xl {
    order: 1;
  }
}/* flexboxgrid overrides */.container-fluid {
  position: relative;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  height: 100%;
}.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}@media (min-width: 768px) {.container {
    width: calc(calc(100vw / 14) * 13);
    width: var(--content-max-width);
    padding-left: 1.25rem;
    padding-left: var(--grid-gutter);
    padding-right: 1.25rem;
    padding-right: var(--grid-gutter)
}
  }/* WITH GUTTER */.row {
  margin-right: -1rem;
  margin-left: -1rem;
}@media (min-width: 768px) {.row {
    margin-right: calc(-1 * 1.25rem);
    margin-right: calc(-1 * var(--grid-gutter));
    margin-left: calc(-1 * 1.25rem);
    margin-left: calc(-1 * var(--grid-gutter))
}
  }[class*="col-"] {
  padding-right: 1rem;
  padding-left: 1rem;
}@media (min-width: 768px) {[class*="col-"] {
    padding-right: 1.25rem;
    padding-right: var(--grid-gutter);
    padding-left: 1.25rem;
    padding-left: var(--grid-gutter)
}
  }html, body {
  padding: 0;
  margin: 0;
}*, *:before, *:after {
  box-sizing: border-box;
}main {
  position: relative;
  min-height: calc(7 * calc(100vh / 14));
  min-height: calc(7 * var(--grid-row));
}img {
  display: block;
  border: 0;
  width: 100%;
  max-width: 100%;
  height: auto;
}figcaption {
  font-size: 16px;
  font-size: var(--font-size-body);
  font-weight: 500;
  font-weight: var(--font-weight-medium);
  margin: 0 0 90px 0;
  max-width: 75%;
}@media (min-width: 768px) {figcaption {
    max-width: 100%
}
  }@font-face {
  font-family: 'Gotham';
  src: url(/static/media/Gotham-Book.aa4208f1.woff2) format("woff2"),
  url(/static/media/Gotham-Book.8566e32e.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}@font-face {
  font-family: 'Gotham';
  src: url(/static/media/Gotham-Medium.4a7d18fd.woff2) format("woff2"),
  url(/static/media/Gotham-Medium.ef3d936b.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}@font-face {
  font-family: 'Gotham';
  src: url(/static/media/Gotham-Bold.0cf2d1ff.woff2) format("woff2"),
  url(/static/media/Gotham-Bold.7c960565.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}html, body {
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-family: var(--font-family-lato);
  font-size: 16px;
  font-size: var(--font-size-body);
  font-weight: 400;
  font-weight: var(--font-weight-regular);
  line-height: 1.625em;
  line-height: var(--font-lineheight-body);
  letter-spacing: .02em;
  background-color: #ffffff;
  background-color: var(--color-white);
  color: #223746;
  color: var(--color-blue-dark);
}* {
  /*
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
          hyphens: auto;
}/* Headers */h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0 0 1.25em 0;
  line-height: 1.2em;
  font-family: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-family: var(--font-family-gotham);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
          hyphens: none;
}h1,
.h1 {
  font-size: 2.25rem;
  font-size: var(--font-size-header-xl);
}h2,
.h2 {
  font-size: 1.75rem;
  font-size: var(--font-size-header-lg);
}h3,
.h3 {
  font-size: 1.5rem;
  font-size: var(--font-size-header-md);
}h4,
.h4 {
  font-size: 1.25rem;
  font-size: var(--font-size-header-sm);
}h5,
.h5 {
  font-size: 1.125rem;
  font-size: var(--font-size-header-xs);
}h6,
.h6 {
  font-size: 16px;
  font-size: var(--font-size-body);
  text-transform: capitalize;
}p {
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-family: var(--font-family-lato);
  margin-top: 0;
  margin-bottom: 1.75rem;
  margin-bottom: var(--margin-p);
}b,
strong {
  font-weight: 700;
  font-weight: var(--font-weight-bold);
}small {
  display: block;
  font-size: .875rem;
  font-size: var(--font-size-small);
  line-height: 1.625em;
  line-height: var(--font-lineheight-body);
}small * {
    font-size: .875rem;
    font-size: var(--font-size-small);
    line-height: 1.625em;
    line-height: var(--font-lineheight-body);
  }a {
  color: #00A0D2;
  color: var(--color-blue);
  font-weight: inherit;
  text-underline-offset: 2px;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
  text-decoration-thickness: .5px;
  text-decoration: none;
}a:hover {
    text-decoration: underline;
  }a.button:hover {
      text-decoration: none;
    }ul, ol {
  margin-top: 0;
  padding-left: 1em;
}address {
  font-style: normal;
}hr {
  border: none;
  background-color: currentColor;
  color: currentColor;
  height: 1px;
  margin: 2rem 0;
  opacity: .2;
}button {
  -webkit-appearance: none;
          appearance: none;
  background-color: transparent;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  color: inherit;
  max-width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
}button[disabled],
  button[aria-disabled] {
    pointer-events: none;
    opacity: .5;
  }.button {
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  margin: 0;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  font-family: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-family: var(--font-family-gotham);
  font-size: 16px;
  font-size: var(--font-size-body);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  line-height: 1.2em;
  line-height: var(--font-lineheight-headers);
  text-transform: uppercase;
  letter-spacing: .03em;
  background-color: #223746;
  background-color: var(--color-blue-dark);
  color: #ffffff;
  color: var(--color-white);
  border: 2px solid #223746;
  border: 2px solid var(--color-blue-dark);
  border-radius: 24px;
  padding: 2px 1.5rem 0 1.5rem;
  width: auto;
  height: 48px;
  transition: opacity .2s;
}@media (min-width: 1024px) {.button {
    height: 45px
}
  }.button.sm {
    height: 36px;
  }.button.inverted {
    background-color: #ffffff;
    background-color: var(--color-white);
    color: #223746;
    color: var(--color-blue-dark);
    border: 2px solid #ffffff;
    border: 2px solid var(--color-white);
  }.button.blue {
    background-color: #00A0D2;
    background-color: var(--color-blue);
    color: #ffffff;
    color: var(--color-white);
    border: 2px solid #00A0D2;
    border: 2px solid var(--color-blue);
  }.button.secondary {
    background-color: transparent;
    border: 2px solid currentColor;
    color: currentColor;
  }.button.link {
    background-color: transparent;
    border: none;
    color: #00A0D2;
    color: var(--color-blue);
  }.button[disabled],
  .button[aria-disabled] {
    pointer-events: none;
    opacity: .5;
  }.button svg + span {
    margin-left: .25em;
  }.button-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -1rem;
}.button-group .button {
    margin-bottom: 1rem;
  }.button-group .button:not(:last-of-type) {
      margin-right: 1rem;
    }fieldset {
  border: none;
  padding: 0;
  margin: 0;
}legend {
  padding: 0;
}label {
  font-family: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-family: var(--font-family-gotham);
  font-weight: 500;
  font-weight: var(--font-weight-medium);
  font-size: .875rem;
  font-size: var(--font-size-small);
  margin-bottom: 5px;
  display: block;
}input[type="range"] {
  -webkit-appearance: none;
          appearance: none;
  background: #223746;
  background: var(--color-blue-dark);
  color: #223746;
  color: var(--color-blue-dark);
  width: 100%;
  height: 4px;
  border-radius: 2px;
  border: none;
}input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
            appearance: none;
    background: white;
    color: #223746;
    color: var(--color-blue-dark);
    border: 2px solid #223746;
    border: 2px solid var(--color-blue-dark);
    width: 1.5rem;
    height: 1.5rem;
    border-radius: .75rem;
  }input:not([type="submit"]):not([type="range"]):not([type="checkbox"]):not([type="radio"]), textarea {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  padding: .75rem;
  background: transparent;
  border-radius: 10px;
  border: 1px solid currentColor;
  font-family: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-family: var(--font-family-gotham);
  font-size: 16px;
  font-size: var(--font-size-body);
  font-weight: 400;
  font-weight: var(--font-weight-regular);
  line-height: 1.625em;
  line-height: var(--font-lineheight-body);
  color: currentColor;
  -webkit-appearance: none;
          appearance: none;
}input:not([type="submit"]):not([type="range"]):not([type="checkbox"]):not([type="radio"])::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: currentColor;
    opacity: .75;
  }input:not([type="submit"]):not([type="range"]):not([type="checkbox"]):not([type="radio"])::placeholder, textarea::placeholder {
    color: currentColor;
    opacity: .75;
  }input:not([type="submit"]):not([type="range"]):not([type="checkbox"]):not([type="radio"])::selection, textarea::selection {
    color: #ffffff;
    color: var(--color-white);
    background: #000000;
    background: var(--color-black);
  }input:not([type="submit"]):not([type="range"]):not([type="checkbox"]):not([type="radio"]):focus, textarea:focus {
    outline: none;
  }input:not([type="submit"]):not([type="range"]):not([type="checkbox"]):not([type="radio"]):focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder {
      opacity: .5;
    }input:not([type="submit"]):not([type="range"]):not([type="checkbox"]):not([type="radio"]):focus::placeholder, textarea:focus::placeholder {
      opacity: .5;
    }input:not([type="submit"]):not([type="range"]):not([type="checkbox"]):not([type="radio"]) + input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]), input:not([type="submit"]):not([type="range"]):not([type="checkbox"]):not([type="radio"]) textarea, textarea + input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]), textarea textarea {
    margin-top: -1rem;
  }textarea {
  border: 1px solid currentColor;
  border-radius: 10px;
  padding: .75rem;
  font-family: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-family: var(--font-family-gotham);
  font-size: 16px;
  font-size: var(--font-size-body);
  line-height: 1.625em;
  line-height: var(--font-lineheight-body);
  resize:vertical;
}.radio,
.checkbox {
  display: block;
  position: relative;
  padding-left: 2rem;
  margin-bottom: 0;
  cursor: pointer;
  font-family: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-family: var(--font-family-gotham);
  font-size: .875rem;
  font-size: var(--font-size-small);
  -webkit-user-select: none;
          user-select: none;
}.radio[aria-disabled="true"], .checkbox[aria-disabled="true"] {
    pointer-events: none;
    opacity: .5;
  }.radio:hover input ~ .checkmark, .checkbox:hover input ~ .checkmark {
      opacity: 1;
    }.radio input, .checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }.radio input:checked ~ .checkmark, .checkbox input:checked ~ .checkmark {
      background-color: currentColor;
    }.radio input:checked ~ .checkmark:after, .checkbox input:checked ~ .checkmark:after {
        display: block;
      }.radio .checklabel, .checkbox .checklabel {
    display: inline-block;
    font-size: .875rem;
    font-size: var(--font-size-small);
    line-height: 1.625em;
    line-height: var(--font-lineheight-body);
    text-align: left;
  }.radio .checkmark, .checkbox .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #ffffff;
    background-color: var(--color-white);
    border: 1px solid currentColor;
    border-radius: 4px;
    transition: opacity .2s;
  }.radio .checkmark:after, .checkbox .checkmark:after {
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      top: 2px;
      width: 8px;
      height: 12px;
      border: solid #223746;
      border: solid var(--color-blue-dark);
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(40deg);
              transform: rotate(40deg);
    }.radio .checkmark {
    border-radius: 100%;
  }.radio .checkmark:after {
      left: 6px;
      top: 6px;
      width: 8px;
      height: 8px;
      border: none;
      background-color: #ffffff;
      background-color: var(--color-white);
      border-radius: 100%;
    }

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.Header_Header__2C_Ub {
  z-index: 1;
  display: flex;
  align-items: center;
  background-color: #223746;
  background-color: var(--color-blue-dark);
  color: #ffffff;
  color: var(--color-white);
  width: 100%;
  height: 90px;
  pointer-events: none;
}
.Header_HeaderWithHero__1hz06 {
  position: absolute;
  background-color: transparent;
}
.Header_nav__3yRlw {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: calc(100vh / 14);
  height: var(--grid-row);
}
.Header_linkBack__34fnr {
  pointer-events: auto;
  font-size: .875rem;
  font-size: var(--font-size-small);
  font-family: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-family: var(--font-family-gotham);
  font-weight: 500;
  font-weight: var(--font-weight-medium);
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  color: #7C7C79;
  color: var(--color-gray);
  transition: color .2s;
}
.Header_linkBack__34fnr:hover {
    color: #223746;
    color: var(--color-blue-dark);
    text-decoration: none;
  }
.Header_linkBack__34fnr svg {
    margin-right: .5rem;
  }

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.Svg_nrep-logo__FDdzs {
  width: 31px;
  min-width: 31px;
  height: 40px;
}
/*
.nrep-logo {
  width: 104px;
  min-width: 104px;
  height: 30px;
}
*/
.Svg_arrow-left__3eEmf {
  width: 18px;
  min-width: 18px;
  height: 6px;
}
.Svg_nav-left-small__19nFG,
.Svg_nav-right-small__3mA2k {
  width: 8px;
  min-width: 8px;
  height: 14px;
}
.Svg_nav-left__163Sx,
.Svg_nav-right__3erPC {
  width: 12px;
  min-width: 12px;
  height: 20px;
}
.Svg_nav-down__HW_Dr,
.Svg_nav-up__MOyG6 {
  width: 20px;
  min-width: 20px;
  height: 12px;
}
.Svg_info__Vyprf,
.Svg_plus__2jfJf {
  width: 20px;
  min-width: 20px;
  height: 20px;
}
.Svg_search__3LrGz,
.Svg_filters__1DQdL {
  width: 24px;
  min-width: 24px;
  height: 24px;
}
.Svg_checkmark__2U45l {
  width: 74px;
  min-width: 74px;
  height: 74px;
}
.Svg_file__owBsZ {
  width: 22px;
  min-width: 22px;
  height: 28px;
}
.Svg_play__2aZSy {
  width: 100px;
  min-width: 100px;
  height: 100px;
}
.Svg_pin__1hrNM {
  width: 14px;
  min-width: 14px;
  height: 17px;
}
.Svg_house__17ko1 {
  width: 15px;
  min-width: 15px;
  height: 16px;
}

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.Logo_Logo__1N0xH {
  display: flex;
  width: 100%;
}
.Logo_linkLogo__2BzP- {
  color: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  pointer-events: auto;
}
@media (min-width: 768px) {
.Logo_linkLogo__2BzP- {
    display: inline-flex;
    justify-content: flex-start;
    width: auto
}
  }
.Logo_linkLogo__2BzP-:hover {
    text-decoration: none;
  }
.Logo_titleWrapper__1z-e3 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media (min-width: 768px) {
.Logo_titleWrapper__1z-e3 {
    justify-content: flex-start
}
  }
.Logo_title__1eYhM {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: .04em;
  text-align: right;
  line-height: 1em;
  margin: 4px 0 0 0;
}
@media (min-width: 768px) {
.Logo_title__1eYhM {
    margin: 0 0 0 87px
}
  }

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.Page_Page__3J6eP {
  width: 100%;
}


/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.PropertiesMap_PropertiesMap__2IZkI {
}
.PropertiesMap_mapContainer__3Zb4L {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.PropertiesMap_map___7pzV {
  width: 100%;
  height: 100%;
}
.PropertiesMap_clusterIcon__2IGdQ {
  background: #223746;
  background: var(--color-blue-dark);
  color: #ffffff;
  color: var(--color-white);
  border-radius: 100%;
  border: 2px solid #ffffff;
  border: 2px solid var(--color-white);
  font-family: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-family: var(--font-family-gotham);
  font-size: .875rem;
  font-size: var(--font-size-small);
  font-weight: 500;
  font-weight: var(--font-weight-medium);
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  box-shadow: var(--box-shadow);
}

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.PropertiesMapFilters_PropertiesMapFilters__8mAmc {
  position: absolute;
  top: 5rem;
  left: 0;
  width: 100%;
  height: calc(100% - 5rem);
  -webkit-filter: drop-shadow(5px 2px 5px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(5px 2px 5px rgba(0, 0, 0, 0.1));
  border-top: 1px solid rgba(207,223,235,0.50196);
  border-top: var(--border-light);
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(-1rem);
          transform: translateY(-1rem);
  transition: visibility .35s, opacity .35s, -webkit-transform .35s cubic-bezier(.5, 1, .5, 1);
  transition: transform .35s cubic-bezier(.5, 1, .5, 1), visibility .35s, opacity .35s;
  transition: transform .35s cubic-bezier(.5, 1, .5, 1), visibility .35s, opacity .35s, -webkit-transform .35s cubic-bezier(.5, 1, .5, 1);
  transition: visibility .35s, opacity .35s, -webkit-transform .35s var(--cubic-ease-out);
  transition: transform .35s var(--cubic-ease-out), visibility .35s, opacity .35s;
  transition: transform .35s var(--cubic-ease-out), visibility .35s, opacity .35s, -webkit-transform .35s var(--cubic-ease-out);
}
.PropertiesMapFilters_PropertiesMapFilters__8mAmc:before {
    z-index: -1;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    background-color: var(--color-white);
    opacity: .9;
  }
.PropertiesMapFilters_PropertiesMapFilters__8mAmc:after {
    z-index: -1;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #223746;
    background: var(--color-blue-dark);
    mix-blend-mode: darken;
    opacity: .05;
  }
@media (min-width: 768px) {
.PropertiesMapFilters_PropertiesMapFilters__8mAmc {
    width: 50vw
}
  }
@media (min-width: 1024px) {
.PropertiesMapFilters_PropertiesMapFilters__8mAmc {
    width: 33.3333vw
}
  }
@media (min-width: 1440px) {
.PropertiesMapFilters_PropertiesMapFilters__8mAmc {
    width: 25vw
}
  }
.PropertiesMapFilters_show__1gVCY {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  visibility: visible;
  opacity: 1;
}
.PropertiesMapFilters_showingNumProps__lnrpz {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  width: calc(100% - 2rem);
  height: 4rem;
  border-radius: 2rem;
  padding: 0 1.25rem 0 calc(calc(100vw / 28) + 1.25rem);
  padding: 0 var(--grid-gutter) 0 calc(var(--grid-column-half) + var(--grid-gutter));
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  background-color: #00A0D2;
  background-color: var(--color-blue);
  color: #ffffff;
  color: var(--color-white);
}
.PropertiesMapFilters_showingNumProps__lnrpz:hover {
    background-color: #223746;
    background-color: var(--color-blue-dark);
  }
.PropertiesMapFilters_buttonFilter__1BWLg {
  z-index: 1;
  position: absolute;
  padding: 12px 1.25rem 12px calc(calc(100vw / 28) + 1.25rem);
  padding: 12px 1.25rem 12px calc(var(--grid-column-half) + var(--grid-gutter));
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  justify-content: space-between;
  align-items: center;
  display: flex;
  top: 0;
  left: 0;
  height: 5rem;
  width: 100%;
  transition: background-color .2s;
}
@media (min-width: 768px) {
.PropertiesMapFilters_buttonFilter__1BWLg {
    width: 50vw
}
  }
@media (min-width: 1024px) {
.PropertiesMapFilters_buttonFilter__1BWLg {
    width: 33.3333vw
}
  }
@media (min-width: 1440px) {
.PropertiesMapFilters_buttonFilter__1BWLg {
    width: 25vw
}
  }
.PropertiesMapFilters_buttonFilter__1BWLg > * {
    position: relative;
  }
.PropertiesMapFilters_buttonFiltersOpen__2_DPy {
  background-color: rgba(255,255,255,0.90196);
  background-color: var(--color-white-90);
}
.PropertiesMapFilters_buttonFiltersOpen__2_DPy .PropertiesMapFilters_buttonBg__3sRWO {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    width: 100%;
    height: 100%;
    background-color: rgba(34,55,70,0.05098);
    background-color: var(--color-blue-dark-5);
    border-radius: 0;
  }
.PropertiesMapFilters_buttonReset__2ygKT {
  z-index: 1;
  position: absolute;
  font-weight: 500;
  font-weight: var(--font-weight-medium);
  font-size: .875rem;
  font-size: var(--font-size-small);
  text-transform: none;
  align-items: center;
  display: flex;
  top: 1.2rem;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: visibility .3s, opacity .3s;
  left: 9rem;
}
@media (min-width: 768px) {
.PropertiesMapFilters_buttonReset__2ygKT {
    left: 10rem
}
  }
@media (min-width: 1440px) {
.PropertiesMapFilters_buttonReset__2ygKT {
    left: 11rem
}
  }
@media (min-width: 1920px) {
.PropertiesMapFilters_buttonReset__2ygKT {
    left: 13rem
}
  }
.PropertiesMapFilters_buttonResetShow__2Oe0g {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
.PropertiesMapFilters_buttonBg__3sRWO {
  z-index: 0;
  position: absolute !important;
  content: '';
  left: 0;
  top: 0;
  -webkit-transform: translate(.75rem, .75rem);
          transform: translate(.75rem, .75rem);
  width: calc(100% - 1.5rem);
  height: calc(100% - 1.5rem);
  background-color: #ffffff;
  background-color: var(--color-white);
  border-radius: 10px;
  border-radius: var(--border-radius);
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  box-shadow: var(--box-shadow);
  transition: width .2s, height .2s, background-color .2s, border-radius .2s, -webkit-transform .2s;
  transition: width .2s, height .2s, background-color .2s, border-radius .2s, transform .2s;
  transition: width .2s, height .2s, background-color .2s, border-radius .2s, transform .2s, -webkit-transform .2s;
}
.PropertiesMapFilters_inputSearchWrapper__2NdmM {
  z-index: 1;
  position: absolute;
  top: 15px;
  left: 15px;
  width: calc(100% - 5rem);
}
@media (min-width: 768px) {
.PropertiesMapFilters_inputSearchWrapper__2NdmM {
    width: calc(50% - 5rem)
}
  }
@media (min-width: 1024px) {
.PropertiesMapFilters_inputSearchWrapper__2NdmM {
    width: calc(33.333% - 5rem)
}
  }
@media (min-width: 1440px) {
.PropertiesMapFilters_inputSearchWrapper__2NdmM {
    width: calc(25% - 5rem)
}
  }
.PropertiesMapFilters_inputSearch__3vsqZ {
  padding-left: 2.75rem !important;
  padding-right: 2.75rem !important;
  margin: 0 !important;
  border: none !important;
}
.PropertiesMapFilters_inputSearch__3vsqZ:focus {
    background-color: rgba(34,55,70,0.05098) !important;
    background-color: var(--color-blue-dark-5) !important;
  }
.PropertiesMapFilters_iconSearch__l9-Fv {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  pointer-events: none;
}
.PropertiesMapFilters_buttonClearSearch__2Fhca {
  position: absolute;
  top: 50%;
  right: 2rem;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  width: 2rem;
  height: 2rem;
}
.PropertiesMapFilters_iconClearSearch__ouvyT {
  -webkit-transform: translate(50%, -50%) rotate(45deg);
          transform: translate(50%, -50%) rotate(45deg);
}
.PropertiesMapFilters_numFilters__1WZuj {
  font-weight: 400;
  font-weight: var(--font-weight-regular);
  margin-left: .25em;
}
.PropertiesMapFilters_numFiltersIcon__2yYja {
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00A0D2;
  background-color: var(--color-blue);
  color: #ffffff;
  color: var(--color-white);
  border-radius: 100%;
  width: 1rem;
  height: 1rem;
}
.PropertiesMapFilters_numFiltersIcon__2yYja small {
    font-size: .5rem;
    font-size: var(--font-size-smaller);
    line-height: 1em;
    height: 1em;
  }
.PropertiesMapFilters_iconBg__2pRO8 {
  position: absolute;
  top: 50%;
  right: .25rem;
  -webkit-transform: translate(-100%, -50%);
          transform: translate(-100%, -50%);
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PropertiesMapFilters_iconBgClose___eLy7 {
  -webkit-transform: translate(-100%, -50%) rotate(45deg);
          transform: translate(-100%, -50%) rotate(45deg);
}
.PropertiesMapFilters_scrollContainer__3VamM {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.PropertiesMapFilters_content__23YNW {
  position: relative;
  padding: 2rem 1.25rem 5rem calc(calc(100vw / 28) + 1.25rem);
  padding: 2rem var(--grid-gutter) 5rem calc(var(--grid-column-half) + var(--grid-gutter));
}
.PropertiesMapFilters_rangeWrapper__3Yr5p {
  margin-bottom: 2rem;
}
.PropertiesMapFilters_title__U75KE {
  margin: 0;
}
.PropertiesMapFilters_buttonClose__Jgre2 {
  position: absolute;
  top: 1.75rem;
  top: var(--margin-p);
  right: 1rem;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
@media (min-width: 768px) {
.PropertiesMapFilters_buttonClose__Jgre2 {
    right: 2rem
}
  }
.PropertiesMapFilters_divider__1JVO_ {
  margin: 0;
}

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.PropertiesMapFilterList_PropertiesMapFilterList__2Nnf0 {
  padding: 0;
  margin: 0 0 2rem 0;
  list-style-type: none;
}
.PropertiesMapFilterList_PropertiesMapFilterList__2Nnf0 li {
    padding: .25rem 0;
  }
.PropertiesMapFilterList_PropertiesMapFilterList__2Nnf0 [class="checkmark"]:after {
      border-color: #ffffff !important;
      border-color: var(--color-white) !important;
    }
.PropertiesMapFilterList_PropertiesMapFilterList__2Nnf0 [class="checklabel"] {
    font-weight: 400 !important;
    font-weight: var(--font-weight-regular) !important;
  }
.PropertiesMapFilterList_listTitle__1Ov04 {
  margin-bottom: 1em;
}

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.Map_Map__lBUCh {
  width: 100%;
  height: 100%;
}
.Map_clusterIcon__1ncL6 {
  background: #223746;
  background: var(--color-blue-dark);
  color: #ffffff;
  color: var(--color-white);
  border-radius: 100%;
  border: 2px solid #ffffff;
  border: 2px solid var(--color-white);
  font-family: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-family: var(--font-family-gotham);
  font-size: .875rem;
  font-size: var(--font-size-small);
  font-weight: 500;
  font-weight: var(--font-weight-medium);
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  box-shadow: var(--box-shadow);
}
.Map_markerLabel__LeGlu {
  //background-color: #223746;
  //background-color: var(--color-blue-dark);
  color: #ffffff !important;
  color: var(--color-white) !important;
  padding: 16px 12px 0 12px;
  //transform: translateY(15px);
  display: inline-block;
  max-width: 100px;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 30px;
  //border-radius: 15px;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !important;
  font-family: var(--font-family-lato) !important;
  font-size: .7rem !important;
  font-weight: 400;
  font-weight: var(--font-weight-regular);
  line-height: 0;
  //border: 1px solid rgba(207,223,235,0.50196);
  //border: var(--border-light);
  //border-color: white;
  //border-width: 2px;
}
.Map_loader__1zewN {
  width: 100%;
  height: 100%;
  padding-bottom: 60%;
  position: relative;
  background-color: #D8D8D8;
  background-color: var(--color-gray-light);
}

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
@-webkit-keyframes LoaderSpinner_progress__3U2iG {
  0% {
    stroke-dashoffset: 100;
  }
  50% {
    stroke-dashoffset: 68;
  }
  51% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -32;
  }
}
@keyframes LoaderSpinner_progress__3U2iG {
  0% {
    stroke-dashoffset: 100;
  }
  50% {
    stroke-dashoffset: 68;
  }
  51% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -32;
  }
}
.LoaderSpinner_LoaderSpinner__1w3Q2 {
  z-index: 1000;
  position: absolute;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LoaderSpinner_LoaderSpinner__1w3Q2 *, .LoaderSpinner_LoaderSpinner__1w3Q2 *:before, .LoaderSpinner_LoaderSpinner__1w3Q2 *:after {
    box-sizing: border-box;
  }
.LoaderSpinner_svg__2Yx2i {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  fill: none;
  stroke: currentColor;
}
.LoaderSpinner_progress__3U2iG {
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  -webkit-animation: LoaderSpinner_progress__3U2iG 1.5s forwards infinite;
          animation: LoaderSpinner_progress__3U2iG 1.5s forwards infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.InfoWindow_InfoWindow__3kS3b {
  display: flex;
  justify-content: space-between;
  width: 250px;
}
@media (max-width: 767px) {
  }
.InfoWindow_InfoWindowImage__17RfW {
  width: 300px;
}
@media (max-width: 767px) {
.InfoWindow_InfoWindowImage__17RfW {
    width: calc(100vw - 2rem)
}
  }
.InfoWindow_InfoWindowImage__17RfW .InfoWindow_content__3MkvM {
    width: 60%;
  }
.InfoWindow_image__CdMEi {
  width: 40%;
  min-height: 100%;
  object-fit: cover;
}
.InfoWindow_content__3MkvM {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 7px 12px 12px;
  font-family: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-family: var(--font-family-gotham);
  font-size: .875rem;
  font-size: var(--font-size-small);
  line-height: 1.2em;
  line-height: var(--font-lineheight-headers);
}
.InfoWindow_content__3MkvM > span {
    padding: .15rem 0;
  }
.InfoWindow_content__3MkvM > span:first-of-type {
      padding-right: 1.5rem;
      font-weight: 500;
      font-weight: var(--font-weight-medium);
    }
.InfoWindow_content__3MkvM > button {
    justify-content: center;
    margin: 15px 0 0 0;
  }
.InfoWindow_segment__jXoF- {
  font-weight: 400;
  font-weight: var(--font-weight-regular);
}
.InfoWindow_status__ke08j {
  font-weight: 400;
  font-weight: var(--font-weight-regular);
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.InfoWindow_status__ke08j > span[class^="dot"] {
      display: flex;
      width: 9px;
      min-width: 9px;
      height: 9px;
      min-height: 9px;
      border-radius: 100%;
      margin-right: .5rem;
      background-color: #000000;
      background-color: var(--color-black);
    }
.InfoWindow_status__ke08j > span[class^="dot"][class*="Development"] {
        background-color: #F0BE41;
        background-color: var(--color-yellow);
      }
.InfoWindow_status__ke08j > span[class^="dot"][class*="Asset-under management"] {
        background-color: #00AF69;
        background-color: var(--color-green);
      }
.InfoWindow_status__ke08j > span[class^="dot"][class*="Pipeline"] {
        background-color: #55325F;
        background-color: var(--color-purple);
      }
.InfoWindow_status__ke08j > span[class^="dot"][class*="Ready for hand-over to AM"] {
        background-color: #00A0D2;
        background-color: var(--color-blue);
      }
.InfoWindow_status__ke08j > span[class^="dot"][class*="Exited"] {
        background-color: #575757;
        background-color: var(--color-gray-dark);
      }
.InfoWindow_label__3Nxg1 {
  white-space: nowrap;
  color: #575757;
  color: var(--color-gray-dark);
  max-width: calc(100% - 1rem);
  overflow: hidden;
}
[class="gm-style-iw gm-style-iw-c"] {
  padding: 0 !important;
  max-width: none !important;
  max-height: none !important;
}
@media (max-width: 767px) {
[class="gm-style-iw gm-style-iw-c"] {
    width: calc(100vw - 2rem);
    min-height: 100px;
    position: fixed;
    bottom: auto;
    -webkit-transform: none;
            transform: none;
    top: 0;
    left: calc(-50% + 1rem)
}
  }
[class="gm-style-iw-d"] {
  overflow: auto !important;
  max-height: none !important;
}
[class="gm-ui-hover-effect"] {
  z-index: 1;
  opacity: 1 !important;
  top: .25rem !important;
  right: .25rem !important;
  background-color: #ffffff !important;
  background-color: var(--color-white) !important;
  border-radius: 100%;
  cursor: pointer;
}
[class="gm-style-iw-t"]:after {
    z-index: 0;
  }

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.ModalProperty_ModalProperty__3K9WJ {
  z-index: 102;
  z-index: var(--z-index-modal);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.93333);
  pointer-events: auto;
  visibility: hidden;
  opacity: 0;
  transition: visibility .35s, opacity .35s;
  will-change: visibility, opacity;
}
.ModalProperty_show__3l9Vb {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
.ModalProperty_show__3l9Vb .ModalProperty_background__3KPjE {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
.ModalProperty_bgClose__5JnfF {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #223746;
  background-color: var(--color-blue-dark);
  opacity: .3;
  cursor: default;
}
.ModalProperty_background__3KPjE {
  display: flex;
  background-color: #ffffff;
  background-color: var(--color-white);
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  box-shadow: var(--box-shadow);
  opacity: 0;
  -webkit-transform: translate(-50%, calc(-50% + 10px));
          transform: translate(-50%, calc(-50% + 10px));
  transition: opacity .35s, -webkit-transform .35s cubic-bezier(.5, 1, .5, 1);
  transition: transform .35s cubic-bezier(.5, 1, .5, 1), opacity .35s;
  transition: transform .35s cubic-bezier(.5, 1, .5, 1), opacity .35s, -webkit-transform .35s cubic-bezier(.5, 1, .5, 1);
  transition: opacity .35s, -webkit-transform .35s var(--cubic-ease-out);
  transition: transform .35s var(--cubic-ease-out), opacity .35s;
  transition: transform .35s var(--cubic-ease-out), opacity .35s, -webkit-transform .35s var(--cubic-ease-out);
  will-change: transform, opacity;
  transition-delay: .2s;
  width: 100vw;
  height: 100%;
}
.ModalProperty_content__3YHX9 {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  max-height: 100%;
}
.ModalProperty_screens__3CAQY {
  display: flex;
  flex-wrap: nowrap;
  transition: -webkit-transform .4s cubic-bezier(.5, 0, .5, 1);
  transition: transform .4s cubic-bezier(.5, 0, .5, 1);
  transition: transform .4s cubic-bezier(.5, 0, .5, 1), -webkit-transform .4s cubic-bezier(.5, 0, .5, 1);
  transition: -webkit-transform .4s var(--cubic-ease-in-out);
  transition: transform .4s var(--cubic-ease-in-out);
  transition: transform .4s var(--cubic-ease-in-out), -webkit-transform .4s var(--cubic-ease-in-out);
}
.ModalProperty_dots__33yYS {
  display: flex;
  justify-content: center;
}
.ModalProperty_buttons__1A-EO {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ModalProperty_buttonOk__21eHY {
  min-width: 12rem;
  justify-content: center;
  margin: 2rem 0 2rem 0;
}
.ModalProperty_buttonSkip__MjnJD {
  min-width: 12rem;
  justify-content: center;
  margin: 0 0 2rem 0;
  color: #00A0D2;
  color: var(--color-blue);
  text-transform: uppercase;
}
.ModalProperty_buttonClose__OMEUS {
  position: absolute;
  top: .5rem;
  right: .5rem;
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #223746;
  background-color: var(--color-blue-dark);
  color: #ffffff;
  color: var(--color-white);
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  box-shadow: var(--box-shadow);
  border-radius: 100%;
}
@media (min-width: 1024px) {
.ModalProperty_buttonClose__OMEUS {
    top: 1rem;
    right: 1rem
}
  }
.ModalProperty_buttonClose__OMEUS svg {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.Property_Property__YgEP4 {
}

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.PropertyHeader_PropertyHeader__1A435 {
  background-color: #ffffff;
  background-color: var(--color-white);
  padding: 3.25rem 1rem 1rem 1rem;
}
.PropertyHeader_logoPlatformContainer__22dsU {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  margin-bottom: 2rem;
  min-width: 30vw;
  min-height: 2rem;
}
@media (min-width: 768px) {
.PropertyHeader_logoPlatformContainer__22dsU {
    min-width: calc(100vw / 7);
    min-width: var(--grid-column-2);
    padding-left: 1rem;
    align-self: flex-start
}
  }
.PropertyHeader_logoPlatformContainer__22dsU figure {
    min-width: calc(100vw / 7);
    min-width: var(--grid-column-2);
  }
@media (min-width: 768px) {
.PropertyHeader_logoPlatformContainer__22dsU figure {
      min-width: calc(calc(100vw / 7) - 1rem);
      min-width: calc(var(--grid-column-2) - 1rem)
  }
    }
.PropertyHeader_logoPlatform__3Zgms {
  background-position: 100% 0;
  background-size: contain;
  background-repeat: no-repeat;
  width: 30vw;
  min-width: 30vw;
  height: 3.5rem;
  min-height: 2rem;
}
@media (min-width: 768px) {
.PropertyHeader_logoPlatform__3Zgms {
    width: 100%;
    min-width: 100%
}
  }
.PropertyHeader_title__gM8gK {
  margin-bottom: .75em;
}
.PropertyHeader_features__3XbFa {
  position: relative;
  padding: 1.25rem 1rem 1rem 1rem;
}
.PropertyHeader_tags__KNxo3 {
  margin-bottom: 2rem;
}
.PropertyHeader_keyFeaturesMobile__20Cww {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.PropertyHeader_keyFeaturesDesktop__11fDr {
  display: none;
}
@media (min-width: 768px) {
.PropertyHeader_keyFeaturesDesktop__11fDr {
    display: flex
}
  }
.PropertyHeader_imageWrapper__1tmQ9 {
  width: 100%;
  margin-bottom: 1.25rem;
}
@media (min-width: 768px) {
.PropertyHeader_imageWrapper__1tmQ9 {
    flex-basis: 40%;
    margin-right: calc(1.25rem * 2);
    margin-right: var(--grid-gutter-2)
}
  }
.PropertyHeader_image__2-YZK {
  object-fit: cover;
  border-radius: 10px;
  border-radius: var(--border-radius);
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background-size: cover;
  background-position: center center;
}
@media (min-width: 768px) {
.PropertyHeader_description__3ljXe {
    flex-basis: 60%
}
  }
.PropertyHeader_buttonToggle__3fxhv {
  position: absolute;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
  top: calc(100% - 4.5rem);
  right: 50%;
  width: 45px;
  min-width: 45px;
  min-height: 45px;
  background-color: #ffffff;
  background-color: var(--color-white);
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  box-shadow: var(--box-shadow);
  border-radius: 100%;
}
@media (min-width: 768px) {
.PropertyHeader_buttonToggle__3fxhv {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    top: 1.125rem;
    right: 1.4rem
}
  }

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.PropertyTags_PropertyTags__2eRDP {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: #2C3033;
  color: var(--color-graphite);
  opacity: .9;
  width: 100%;
}
.PropertyTags_platform__1cH4R {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.PropertyTags_platform__1cH4R:not(:last-of-type) {
    margin: 0 0 1rem 0;
  }
.PropertyTags_platform__1cH4R svg {
    width: 15px;
    margin-right: .5rem;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }
.PropertyTags_platform__1cH4R span {
    font-family: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-family: var(--font-family-gotham);
    font-size: .875rem;
    font-size: var(--font-size-small);
    font-weight: 400;
    font-weight: var(--font-weight-regular);
    line-height: 1.2em;
    line-height: var(--font-lineheight-headers);
  }
.PropertyTags_platform__1cH4R span[class^="dot"] {
      display: flex;
      width: 15px;
      min-width: 15px;
      height: 15px;
      min-height: 15px;
      border-radius: 100%;
      margin: 0 .5rem 0 0;
    }
.PropertyTags_platform__1cH4R span[class^="dot"][class*="Development"] {
        background-color: #F0BE41;
        background-color: var(--color-yellow);
      }
.PropertyTags_platform__1cH4R span[class^="dot"][class*="Asset-under management"] {
        background-color: #00AF69;
        background-color: var(--color-green);
      }
.PropertyTags_platform__1cH4R span[class^="dot"][class*="Pipeline"] {
        background-color: #55325F;
        background-color: var(--color-purple);
      }
.PropertyTags_platform__1cH4R span[class^="dot"][class*="Ready for hand-over to AM"] {
        background-color: #00A0D2;
        background-color: var(--color-blue);
      }
.PropertyTags_platform__1cH4R span[class^="dot"][class*="Exited"] {
        background-color: #575757;
        background-color: var(--color-gray-dark);
      }

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.ImageGalleryOverlay_ImageGalleryOverlay__21J-c {
  z-index: 102;
  z-index: var(--z-index-modal);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #223746;
  background-color: var(--color-blue-dark);
  color: #ffffff;
  color: var(--color-white);
  padding: calc(100vh / 7) 1.25rem calc(100vh / 7) 1.25rem;
  padding: var(--grid-row-2) var(--grid-gutter) var(--grid-row-2) var(--grid-gutter);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
@media (min-width: 1024px) {
.ImageGalleryOverlay_ImageGalleryOverlay__21J-c {
    padding: calc(100vh / 7) calc(100vw / 7) 0 calc(100vw / 7);
    padding: var(--grid-row-2) var(--grid-column-2) 0 var(--grid-column-2)
}
  }
.ImageGalleryOverlay_button__lNLPM {
  position: fixed;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #223746;
  color: var(--color-blue-dark);
  border-radius: 100%;
  background-color: #ffffff;
  background-color: var(--color-white);
  opacity: .85;
  transition: opacity 1s;
}
@media (min-width: 1024px) {
.ImageGalleryOverlay_button__lNLPM {
    width: 3.5rem;
    height: 3.5rem
}
  }
.ImageGalleryOverlay_buttonClose__3zSuk {
  top: calc(100vh / 14);
  top: var(--grid-row);
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg);
  background-color: #ffffff;
  background-color: var(--color-white);
}
.ImageGalleryOverlay_buttonClose__3zSuk:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(34,55,70,0.10196);
    background-color: var(--color-blue-dark-10);
    border-radius: 100%;
  }
.ImageGalleryOverlay_buttonNav__ACmyO {
  display: none;
}
@media (hover: hover) and (pointer: fine) {
.ImageGalleryOverlay_buttonNav__ACmyO {
    display: flex
}
  }
.ImageGalleryOverlay_buttonLeft__1TVnk {
  top: calc(50% + calc(100vh / 14));
  top: calc(50% + var(--grid-row));
  left: calc(100vw / 14);
  left: var(--grid-column);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ImageGalleryOverlay_buttonRight__3Tmgl {
  top: calc(50% + calc(100vh / 14));
  top: calc(50% + var(--grid-row));
  right: calc(100vw / 14);
  right: var(--grid-column);
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}
.ImageGalleryOverlay_dots__gDUyG {
  position: fixed;
  bottom: calc(100vh / 14);
  bottom: var(--grid-row);
  left: 50%;
  display: flex;
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
@media (min-width: 1024px) {
.ImageGalleryOverlay_dots__gDUyG {
    bottom: calc(100vh / 14);
    bottom: var(--grid-row)
}
  }
.ImageGalleryOverlay_imagesContainer__w-qDl {
  display: block;
  width: 100%;
  max-width: 100%;
  height: calc(100vh - calc(100vh / 7));
  height: calc(100vh - var(--grid-row-2));
}
.ImageGalleryOverlay_scroller__3u6i0 {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: calc(100vh - calc(100vh / 7));
  height: calc(100vh - var(--grid-row-2));
}
.ImageGalleryOverlay_item__3JGNB {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding-bottom: calc(100vh / 7);
  padding-bottom: var(--grid-row-2);
  top: 0;
  left: 0;
  width: 100%;
  min-height: calc(100vh - calc(100vh / 7));
  min-height: calc(100vh - var(--grid-row-2));
  transition: opacity .2s;
}
@media (hover: hover) and (pointer: fine) {
.ImageGalleryOverlay_item__3JGNB {
    transition: opacity .3s cubic-bezier(.5, 0, .5, 1);
    transition: opacity .3s var(--cubic-ease-in-out)
}
  }
.ImageGalleryOverlay_figure__1I4aj {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ImageGalleryOverlay_figure__1I4aj figcaption {
    margin: 1rem 0 0 0;
    padding-bottom: 2rem;
    max-width: 100%;
  }
@media (min-width: 1024px) {
.ImageGalleryOverlay_figure__1I4aj figcaption {
      max-width: 40%;
      margin-right: 60%
  }
    }
.ImageGalleryOverlay_image__JiqWJ {
  border-radius: 10px;
  border-radius: var(--border-radius);
  max-height: calc(10 * calc(100vh / 14));
  max-height: calc(10 * var(--grid-row));
  width: auto;
}

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.Image_figure__25JWM {
  max-width: 100%;
  margin: 0;
}
.Image_button__2pwfW {
  display: block;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  border-radius: var(--border-radius);
}
.Image_button__2pwfW img {
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
  }
.Image_button__2pwfW:hover img {
      -webkit-transform: scale(1.05);
              transform: scale(1.05);
    }

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.DotNavigation_DotNavigation__3_t4A {
  height: 13px;
}
.DotNavigation_dot__1HwoA {
  position: relative;
  width: 13px;
  height: 13px;
  cursor: pointer;
  transition: -webkit-transform .1s;
  transition: transform .1s;
  transition: transform .1s, -webkit-transform .1s;
}
.DotNavigation_dot__1HwoA:before {
    position: absolute;
    content: '';
    top: 3px;
    left: 3px;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: currentColor;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    box-shadow: var(--box-shadow);
    opacity: .5;
  }
.DotNavigation_dot__1HwoA:hover {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
.DotNavigation_dotActive__3R91w {
}
.DotNavigation_dotActive__3R91w:before {
    opacity: 1;
  }

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.PropertyKeyFigures_sectionKeyFigures__1hAo3 {
  background-color: rgba(34,55,70,0.10196);
  background-color: var(--color-blue-dark-10);
  padding: calc(100vh / 14) 0;
  padding: var(--grid-row) 0;
}
.PropertyKeyFigures_PropertyKeyFigures__3viqK {
  width: 100%;
  border-top: 1px solid rgba(207,223,235,0.50196);
  border-top: var(--border-light);
  border-left: 1px solid rgba(207,223,235,0.50196);
  border-left: var(--border-light);
  border-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 768px) {
.PropertyKeyFigures_PropertyKeyFigures__3viqK {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto
}
  }
@media (min-width: 1200px) {
.PropertyKeyFigures_PropertyKeyFigures__3viqK {
    grid-template-columns: 33.3333% 33.3333% 33.3333%;
    grid-template-rows: auto
}
  }
.PropertyKeyFigures_logosSustain__QEIJP {
  display: flex;
  justify-items: flex-start;
  max-width: 200px;
  margin-bottom: 2rem;
}
.PropertyKeyFigures_logosSustain__QEIJP > div {
    max-height: 5rem;
  }
.PropertyKeyFigures_logosSustain__QEIJP > div img {
      width: auto;
      height: 100%;
    }
.PropertyKeyFigures_desc__195x1 {
  margin-bottom: 2rem;
}
.PropertyKeyFigures_status__3fPWl {
  font-weight: 400;
  font-weight: var(--font-weight-regular);
  display: flex;
  align-items: flex-start;
  line-height: 1.2em;
  line-height: var(--font-lineheight-headers);
}
.PropertyKeyFigures_status__3fPWl > span[class^="dot"] {
      display: flex;
      width: 9px;
      min-width: 9px;
      height: 9px;
      min-height: 9px;
      border-radius: 100%;
      margin: .15rem .5rem 0 0;
    }
.PropertyKeyFigures_status__3fPWl > span[class*="Development"] {
      background-color: #F0BE41;
      background-color: var(--color-yellow);
    }
.PropertyKeyFigures_status__3fPWl > span[class*="Asset-under management"] {
      background-color: #00AF69;
      background-color: var(--color-green);
    }
.PropertyKeyFigures_status__3fPWl > span[class*="Pipeline"] {
      background-color: #55325F;
      background-color: var(--color-purple);
    }
.PropertyKeyFigures_status__3fPWl > span[class*="Ready for hand-over to AM"] {
      background-color: #00A0D2;
      background-color: var(--color-blue);
    }
.PropertyKeyFigures_status__3fPWl > span[class*="Exited"] {
      background-color: #575757;
      background-color: var(--color-gray-dark);
    }
.PropertyKeyFigures_statusLabel__1PIiM {
  line-height: 1.2em;
  line-height: var(--font-lineheight-headers);
  color: #575757;
  color: var(--color-gray-dark);
  font-size: .875rem;
  font-size: var(--font-size-small);
}

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.PropertyKeyFiguresItem_PropertyKeyFiguresItem__3_PpM {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem 1.5rem;
  width: 100%;
  min-height: 71px;
  margin: 0;
  font-family: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-family: var(--font-family-gotham);
  border-bottom: 1px solid rgba(207,223,235,0.50196);
  border-bottom: var(--border-light);
  border-right: 1px solid rgba(207,223,235,0.50196);
  border-right: var(--border-light);
  border-color: white;
}
.PropertyKeyFiguresItem_PropertyKeyFiguresItem__3_PpM dt {
    text-transform: uppercase;
    font-size: .875rem;
    font-size: var(--font-size-small);
    font-weight: 500;
    font-weight: var(--font-weight-medium);
    line-height: 1.2em;
    line-height: var(--font-lineheight-headers);
    white-space: nowrap;
  }
.PropertyKeyFiguresItem_PropertyKeyFiguresItem__3_PpM dd {
    margin: .5rem 0 0 0;
    font-size: 1.5rem;
    font-size: var(--font-size-header-md);
    font-weight: 700;
    font-weight: var(--font-weight-bold);
  }

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.PropertyContacts_PropertyContacts__1NXr7 {
  width: 100%;
  background-color: #F0BE41;
  background-color: var(--color-yellow);
  padding: calc(calc(100vh / 14) - 2rem) 0 calc(100vh / 14) 0;
  padding: calc(var(--grid-row) - 2rem) 0 var(--grid-row) 0;
}
.PropertyContacts_contact__PlmS8 {
  margin-top: 2rem;
}
.PropertyContacts_contact__PlmS8 > * {
    display: block;
    color: #223746;
    color: var(--color-blue-dark);
  }
.PropertyContacts_contact__PlmS8 h6 {
    margin-bottom: .5rem;
  }
/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.Styleguide_Styleguide__3bguk {
  width: 100%;
}
.Styleguide_Styleguide__3bguk section {
    margin: calc(100vh / 14) 0;
    margin: var(--grid-row) 0;
  }
.Styleguide_color__2Yq88 {
  width: 100%;
  height: 100px;
  padding: .5rem;
  margin-bottom: 3rem;
}
.Styleguide_colorBlueDark__2qb-0 {
  background-color: #223746;
  background-color: var(--color-blue-dark);
  color: #ffffff;
  color: var(--color-white);
}
.Styleguide_colorGraphite__2cG-R {
  background-color: #2C3033;
  background-color: var(--color-graphite);
  color: #ffffff;
  color: var(--color-white);
}
.Styleguide_colorBlack__1IO2O {
  background-color: #000000;
  background-color: var(--color-black);
  color: #ffffff;
  color: var(--color-white);
}
.Styleguide_colorWhite__2nbVa {
  background-color: #ffffff;
  background-color: var(--color-white);
  color: #223746;
  color: var(--color-blue-dark);
  border: 1px solid currentColor;
}
.Styleguide_colorBlue__2M_vN {
  background-color: #00A0D2;
  background-color: var(--color-blue);
  color: #ffffff;
  color: var(--color-white);
}
.Styleguide_colorGreen__3cF5z {
  background-color: #00AF69;
  background-color: var(--color-green);
  color: #ffffff;
  color: var(--color-white);
}
.Styleguide_colorPurple__1B0kw {
  background-color: #55325F;
  background-color: var(--color-purple);
  color: #ffffff;
  color: var(--color-white);
}
.Styleguide_colorYellow__2ISPN {
  background-color: #F0BE41;
  background-color: var(--color-yellow);
  color: #223746;
  color: var(--color-blue-dark);
}
.Styleguide_icon__UK4pT {
  height: 100px;
}
.Styleguide_bgDark__1q9BX {
  padding: calc(100vh / 14) 0;
  padding: var(--grid-row) 0;
  background-color: #223746;
  background-color: var(--color-blue-dark);
  color: #ffffff;
  color: var(--color-white);
}

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.TitleDescription_TitleDescription__halMj {
  position: relative;
  padding: 50px 0;
  padding: var(--padding-section-xs) 0;
}
@media (min-width: 768px) {
.TitleDescription_TitleDescription__halMj {
    padding: 75px 0;
    padding: var(--padding-section-md) 0
}
  }
.TitleDescription_title__Y4-Fy {
  margin: 0;
  overflow: hidden;
}
.TitleDescription_description__QA74I {
  margin-bottom: calc(-1 * 1.75rem);
  margin-bottom: calc(-1 * var(--margin-p));
  margin-top: 25px;
}
@media (min-width: 768px) {
.TitleDescription_description__QA74I {
    margin-top: 50px
}
  }


/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.PropertiesFeatured_PropertiesFeatured__2FiEw {

}
.PropertiesFeatured_scrollContainer__1t49- {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}
.PropertiesFeatured_properties__1M_o8 {
  width: 100%;
  display: block;
  transition: -webkit-transform .45s cubic-bezier(.5, 0, .5, 1);
  transition: transform .45s cubic-bezier(.5, 0, .5, 1);
  transition: transform .45s cubic-bezier(.5, 0, .5, 1), -webkit-transform .45s cubic-bezier(.5, 0, .5, 1);
  transition: -webkit-transform .45s var(--cubic-ease-in-out);
  transition: transform .45s var(--cubic-ease-in-out);
  transition: transform .45s var(--cubic-ease-in-out), -webkit-transform .45s var(--cubic-ease-in-out);
}
@media (min-width: 1024px) {
.PropertiesFeatured_properties__1M_o8 {
    display: flex;
    height: calc(100vh - 193px);
    transition: -webkit-transform .75s cubic-bezier(.5, 0, .5, 1);
    transition: transform .75s cubic-bezier(.5, 0, .5, 1);
    transition: transform .75s cubic-bezier(.5, 0, .5, 1), -webkit-transform .75s cubic-bezier(.5, 0, .5, 1);
    transition: -webkit-transform .75s var(--cubic-ease-in-out);
    transition: transform .75s var(--cubic-ease-in-out);
    transition: transform .75s var(--cubic-ease-in-out), -webkit-transform .75s var(--cubic-ease-in-out)
}
  }
.PropertiesFeatured_dots__2F3RE {
  position: absolute;
  z-index: 1;
  top: 60vw;
  left: 50%;
  display: flex;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
@media (min-width: 1024px) {
.PropertiesFeatured_dots__2F3RE {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    top: auto;
    bottom: calc(calc(calc(100vh / 14) * .5) + 15px);
    bottom: calc(var(--grid-row-half) + 15px);
    left: calc(calc(100vw / 28) + 1.25rem);
    left: calc(var(--grid-column-half) + var(--grid-gutter))
}
  }
.PropertiesFeatured_navigation__3pA4M {
  z-index: 1;
  position: absolute;
  top: 53vw;
  right: 16px;
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  display: flex;
  padding: 4px;
}
@media (min-width: 1024px) {
.PropertiesFeatured_navigation__3pA4M {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    top: auto;
    bottom: calc(calc(100vh / 14) * .5);
    bottom: var(--grid-row-half);
    right: auto;
    left: calc(7 * calc(100vw / 28));
    left: calc(7 * var(--grid-column-half))
}
  }
@media (min-width: 1440px) {
.PropertiesFeatured_navigation__3pA4M {
    left: calc(6 * calc(100vw / 28));
    left: calc(6 * var(--grid-column-half))
}
  }
@media (min-width: 1920px) {
.PropertiesFeatured_navigation__3pA4M {
    left: calc(4.5 * calc(100vw / 28));
    left: calc(4.5 * var(--grid-column-half))
}
  }
.PropertiesFeatured_navButton__1FRbv {
  z-index: 2;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: #ffffff;
  background-color: var(--color-white);
  color: #223746;
  color: var(--color-blue-dark);
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  box-shadow: var(--box-shadow);
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 1024px) {
.PropertiesFeatured_navButton__1FRbv {
    background-color: #223746;
    background-color: var(--color-blue-dark);
    color: #ffffff;
    color: var(--color-white)
}
  }
.PropertiesFeatured_navButton__1FRbv:first-of-type {
    margin-right: 15px;
  }
.PropertiesFeatured_navButton__1FRbv:first-of-type svg {
      margin-right: 2px;
    }
.PropertiesFeatured_navButton__1FRbv:last-of-type svg {
      margin-left: 2px;
    }
.PropertiesFeatured_navButton__1FRbv * {
    pointer-events: none;
  }

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.PropertyFeatured_PropertyFeatured__BonNM {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity .5s cubic-bezier(.5, 0, .5, 1);
  transition: opacity .5s var(--cubic-ease-in-out);
}
@media (min-width: 1024px) {
.PropertyFeatured_PropertyFeatured__BonNM {
    height: 100%
}
  }
@media (min-width: 1024px) {
.PropertyFeatured_PropertyFeatured__BonNM [class*="container"],
  .PropertyFeatured_PropertyFeatured__BonNM [class*="row"],
  .PropertyFeatured_PropertyFeatured__BonNM [class*="col-"] {
      height: 100%
  }
    }
.PropertyFeatured_PropertyFeatured__BonNM [class*="container"],
  .PropertyFeatured_PropertyFeatured__BonNM [class*="col-"] {
    position: relative;
  }
.PropertyFeatured_PropertyFeatured__BonNM [class*="container"],
  .PropertyFeatured_PropertyFeatured__BonNM [class*="row"] {
    pointer-events: none;
  }
.PropertyFeatured_PropertyFeatured__BonNM [class*="col-"] {
    pointer-events: auto;
  }
.PropertyFeatured_imageLink__18mp8 {
  display: block;
  position: relative;
  transition: -webkit-transform .5s cubic-bezier(.5, 0, .5, 1);
  transition: transform .5s cubic-bezier(.5, 0, .5, 1);
  transition: transform .5s cubic-bezier(.5, 0, .5, 1), -webkit-transform .5s cubic-bezier(.5, 0, .5, 1);
  transition: -webkit-transform .5s var(--cubic-ease-in-out);
  transition: transform .5s var(--cubic-ease-in-out);
  transition: transform .5s var(--cubic-ease-in-out), -webkit-transform .5s var(--cubic-ease-in-out);
}
@media (min-width: 1024px) {
.PropertyFeatured_imageLink__18mp8 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

    .PropertyFeatured_imageLink__18mp8:hover {
      -webkit-transform: scale(1.02) !important;
              transform: scale(1.02) !important;
    }
  }
.PropertyFeatured_image__V3DaR {
  display: block;
  position: relative;
  width: 100%;
  height: 56.25vw;
  object-fit: cover;
}
@media (min-width: 1024px) {
.PropertyFeatured_image__V3DaR {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}
  }
@-webkit-keyframes PropertyFeatured_imageEnterPrevIn__2Wbv2 {
  0% {
    -webkit-transform: translateX(-10%);
            transform: translateX(-10%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes PropertyFeatured_imageEnterPrevIn__2Wbv2 {
  0% {
    -webkit-transform: translateX(-10%);
            transform: translateX(-10%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes PropertyFeatured_imageEnterPrevOut__2sPYR {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(10%);
            transform: translateX(10%);
  }
}
@keyframes PropertyFeatured_imageEnterPrevOut__2sPYR {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(10%);
            transform: translateX(10%);
  }
}
@-webkit-keyframes PropertyFeatured_imageEnterNextIn__30b-U {
  0% {
    -webkit-transform: translateX(10%);
            transform: translateX(10%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes PropertyFeatured_imageEnterNextIn__30b-U {
  0% {
    -webkit-transform: translateX(10%);
            transform: translateX(10%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes PropertyFeatured_imageEnterNextOut__2iNcG {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-10%);
            transform: translateX(-10%);
  }
}
@keyframes PropertyFeatured_imageEnterNextOut__2iNcG {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-10%);
            transform: translateX(-10%);
  }
}
.PropertyFeatured_imagePrevIn__2sMam {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-animation: PropertyFeatured_imageEnterPrevIn__2Wbv2 .5s forwards cubic-bezier(.5, 0, .5, 1);
          animation: PropertyFeatured_imageEnterPrevIn__2Wbv2 .5s forwards cubic-bezier(.5, 0, .5, 1);
  -webkit-animation: PropertyFeatured_imageEnterPrevIn__2Wbv2 .5s forwards var(--cubic-ease-in-out);
          animation: PropertyFeatured_imageEnterPrevIn__2Wbv2 .5s forwards var(--cubic-ease-in-out);
}
.PropertyFeatured_imagePrevOut__7bzZO {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-animation: PropertyFeatured_imageEnterPrevOut__2sPYR .5s forwards cubic-bezier(.5, 0, .5, 1);
          animation: PropertyFeatured_imageEnterPrevOut__2sPYR .5s forwards cubic-bezier(.5, 0, .5, 1);
  -webkit-animation: PropertyFeatured_imageEnterPrevOut__2sPYR .5s forwards var(--cubic-ease-in-out);
          animation: PropertyFeatured_imageEnterPrevOut__2sPYR .5s forwards var(--cubic-ease-in-out);
}
.PropertyFeatured_imageNextIn__2fJpN {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-animation: PropertyFeatured_imageEnterNextIn__30b-U .5s forwards cubic-bezier(.5, 0, .5, 1);
          animation: PropertyFeatured_imageEnterNextIn__30b-U .5s forwards cubic-bezier(.5, 0, .5, 1);
  -webkit-animation: PropertyFeatured_imageEnterNextIn__30b-U .5s forwards var(--cubic-ease-in-out);
          animation: PropertyFeatured_imageEnterNextIn__30b-U .5s forwards var(--cubic-ease-in-out);
}
.PropertyFeatured_imageNextOut__1vnVi {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-animation: PropertyFeatured_imageEnterNextOut__2iNcG .5s forwards cubic-bezier(.5, 0, .5, 1);
          animation: PropertyFeatured_imageEnterNextOut__2iNcG .5s forwards cubic-bezier(.5, 0, .5, 1);
  -webkit-animation: PropertyFeatured_imageEnterNextOut__2iNcG .5s forwards var(--cubic-ease-in-out);
          animation: PropertyFeatured_imageEnterNextOut__2iNcG .5s forwards var(--cubic-ease-in-out);
}
.PropertyFeatured_background__3WqC0 {
  display: none;
}
@media (min-width: 1024px) {
.PropertyFeatured_background__3WqC0 {
    z-index: 0;
    display: block;
    position: absolute;
    top: 0;
    left: calc(-1 * calc(100vw / 28));
    left: calc(-1 * var(--grid-column-half));
    width: calc(100% + calc(100vw / 14));
    width: calc(100% + var(--grid-column));
    height: 100%;
    background-color: #ffffff;
    background-color: var(--color-white);
    opacity: .9
}
  }
.PropertyFeatured_content__2zn2T {
  z-index: 1;
  position: relative;
  padding: calc(100vh / 14) 0;
  padding: var(--grid-row) 0;
}
@media (min-width: 1024px) {
.PropertyFeatured_content__2zn2T {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start
}

    .PropertyFeatured_content__2zn2T > * {
      margin-bottom: calc(calc(100vh / 14) * .5);
      margin-bottom: var(--grid-row-half);
    }
  }
.PropertyFeatured_logoPlatform__3E7w0 {
  height: 2.5rem;
  width: auto;
}
@media (max-width: 1023px) {
.PropertyFeatured_logoPlatform__3E7w0 {
    margin-bottom: 2rem
}
  }
.PropertyFeatured_tags__2AJ3v {
  margin-bottom: calc(calc(100vh / 14) * .5);
  margin-bottom: var(--grid-row-half);
}
@media (min-width: 1024px) {
.PropertyFeatured_description__25RLU {
    max-height: 25rem;
    overflow: hidden
}
  }

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.Platforms_Platforms__2sVT2 {
  display: flex;
  flex-direction: column;
  padding: 0 0 calc(100vh / 14) 0;
  padding: 0 0 var(--grid-row) 0;
  background-color: rgba(34,55,70,0.10196);
  background-color: var(--color-blue-dark-10);
}

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.PlatformFeatured_PlatformFeatured__3IB0A {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
  background-color: #ffffff;
  background-color: var(--color-white);
  color: inherit;
  border: 2px solid #D8D8D8;
  border: var(--border-dark);
  border-radius: 10px;
  border-radius: var(--border-radius);
  margin-bottom: calc(1.25rem * 2);
  margin-bottom: var(--grid-gutter-2);
  height: calc(100% - calc(1.25rem * 2));
  height: calc(100% - var(--grid-gutter-2));
}
.PlatformFeatured_PlatformFeatured__3IB0A:hover {
    text-decoration: none;
  }
.PlatformFeatured_logo__3Im3J {
  height: 5rem;
  width: 50%;
  object-fit: contain;
  object-position: left top;
}
.PlatformFeatured_tagLine__3BU-t {
  margin-top: 2rem;
  color: inherit;
}

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.VimeoPlayer_VimeoPlayer__6b-SN {
  position: relative;
  display: flex;
  padding-bottom: 56.25%;
  width: 100%;
  height: 0;
  overflow: hidden;
  background-color: #000000;
  background-color: var(--color-black);
  transition: opacity .75s, -webkit-transform .75s;
  transition: opacity .75s, transform .75s;
  transition: opacity .75s, transform .75s, -webkit-transform .75s;
}
.VimeoPlayer_VimeoPlayerBackground__2nz2J {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.VimeoPlayer_loader__2BrOF {
  z-index: 0 !important;
  color: #ffffff;
  color: var(--color-white);
}
.VimeoPlayer_frame__2pQsd {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: opacity 1s;
}
.VimeoPlayer_videoContainer__219pV {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.VimeoPlayer_videoContainer__219pV iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
.VimeoPlayer_videoPlayer__yworn {
  width: 100%;
  height: 100%;
  transition: opacity .5s;
}
.VimeoPlayer_buttonPlay__eBy3l {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(34,55,70,0.6);
  background-color: var(--color-blue-dark-60);
  color: #ffffff;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
}
.VimeoPlayer_buttonPlay__eBy3l:hover .VimeoPlayer_iconPlay__2-Hv0 {
      -webkit-transform: scale(.77);
              transform: scale(.77);
    }
.VimeoPlayer_iconPlay__2-Hv0 {
  opacity: .9;
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  box-shadow: var(--box-shadow);
  -webkit-transform: scale(.7);
          transform: scale(.7);
  transition: -webkit-transform .1s cubic-bezier(.5, 0, .5, 1);
  transition: transform .1s cubic-bezier(.5, 0, .5, 1);
  transition: transform .1s cubic-bezier(.5, 0, .5, 1), -webkit-transform .1s cubic-bezier(.5, 0, .5, 1);
  transition: -webkit-transform .1s var(--cubic-ease-in-out);
  transition: transform .1s var(--cubic-ease-in-out);
  transition: transform .1s var(--cubic-ease-in-out), -webkit-transform .1s var(--cubic-ease-in-out);
}

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.Media_Media__1REvR {
  max-width: 100%;
  overflow: hidden;
  padding: 0 !important;
}

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.RelatedItems_RelatedItems__8xhWO {
  background-color: #223746;
  background-color: var(--color-blue-dark);
  color: #ffffff;
  color: var(--color-white);
  padding-bottom: calc(100vh / 14);
  padding-bottom: var(--grid-row);
}
@media (min-width: 768px) {
.RelatedItems_RelatedItems__8xhWO {
    padding-bottom: calc(100vh / 7);
    padding-bottom: var(--grid-row-2)
}
  }
.RelatedItems_titleDescription__fT-ul {
  background-color: #223746;
  background-color: var(--color-blue-dark);
  padding: 50px 0 calc(50px + 1.75rem) 0;
  padding: 50px 0 calc(50px + var(--margin-p)) 0;
  margin: 0 !important;
}
@media (min-width: 768px) {
.RelatedItems_titleDescription__fT-ul {
    padding: 100px 0 calc(100px + 1.75rem) 0;
    padding: 100px 0 calc(100px + var(--margin-p)) 0
}
  }
.RelatedItems_header__20aAP {
  padding-bottom: calc(100vh / 14);
  padding-bottom: var(--grid-row);
}

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.RelatedItem_RelatedItem__1Y6y- {
  margin-bottom: calc(100vh / 7);
  margin-bottom: var(--grid-row-2);
}
@media (min-width: 768px) {
.RelatedItem_RelatedItem__1Y6y- {
    margin-bottom: 0
}
  }
.RelatedItem_image__3uwy0 {
  margin-bottom: calc(100vh / 14);
  margin-bottom: var(--grid-row);
  border-radius: 10px;
  border-radius: var(--border-radius);
}
@media (min-width: 768px) {
.RelatedItem_image__3uwy0 {
    margin-bottom: calc(calc(100vh / 14) * .5);
    margin-bottom: var(--grid-row-half)
}
  }

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.DataList_DataList__1kZEU {
  font-family: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-family: var(--font-family-gotham);
  padding: 50px 0;
  padding: var(--padding-section-xs) 0;
}
@media (min-width: 768px) {
.DataList_DataList__1kZEU {
    padding: 75px 0;
    padding: var(--padding-section-md) 0
}
  }
.DataList_list__gvnX8 {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  background-color: var(--color-white);
  border: 2px solid #D8D8D8;
  border: var(--border-dark);
  border-radius: 10px;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
}
@media (min-width: 1024px) {
.DataList_list__gvnX8 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr
}
  }

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.DataListItem_DataListItem__-08el {
  display: flex;
  padding: 2rem 2rem 2rem 3rem;

}
@media (max-width: 1023px) {
    .DataListItem_DataListItem__-08el:not(:last-of-type) {
      border-bottom: 2px solid #D8D8D8;
      border-bottom: var(--border-dark);
    }
  }
@media (min-width: 1024px) {
.DataListItem_DataListItem__-08el {
    padding: 2rem 2rem 2rem 80px;
    border-bottom: 2px solid #D8D8D8;
    border-bottom: var(--border-dark)

}

    .DataListItem_DataListItem__-08el:nth-last-child(1),
    .DataListItem_DataListItem__-08el:nth-last-child(2),
    .DataListItem_DataListItem__-08el:nth-last-child(3) {
      border-bottom: none;
    }

    .DataListItem_DataListItem__-08el:nth-of-type(3n-1),
    .DataListItem_DataListItem__-08el:nth-of-type(3n-2) {
      border-right: 2px solid #D8D8D8;
      border-right: var(--border-dark);
    }
  }
.DataListItem_DataListItem__-08el dl {
    margin: 0;
  }
.DataListItem_DataListItem__-08el dt {
    font-size: .875rem;
    font-size: var(--font-size-small);
    text-transform: uppercase;
    line-height: 1.2em;
    line-height: var(--font-lineheight-headers);
    margin-bottom: .5em;
  }
.DataListItem_DataListItem__-08el dd {
    margin: 0;
    font-size: 1.75rem;
    font-weight: 700;
    font-weight: var(--font-weight-bold);
    line-height: 1em;
  }
@media (min-width: 1024px) {
.DataListItem_DataListItem__-08el dd {
      font-size: 2.625rem
  }
    }
.DataListItem_DataListItem__-08el dd small {
      display: inline;
      font-size: 1.25rem;
    }
@media (min-width: 1024px) {
.DataListItem_DataListItem__-08el dd small {
        font-size: 2.25rem
    }
      }

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.DownloadLink_DownloadLink__3MaPA {
  font-family: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-family: var(--font-family-gotham);
  padding: 50px 0;
  padding: var(--padding-section-xs) 0;
}
@media (min-width: 768px) {
.DownloadLink_DownloadLink__3MaPA {
    padding: 75px 0;
    padding: var(--padding-section-md) 0
}
  }
.DownloadLink_frame__1YEoh {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #D8D8D8;
  border: var(--border-dark);
  border-radius: 10px;
  border-radius: var(--border-radius);
  background-color: #ffffff;
  background-color: var(--color-white);
  padding: 27px 44px 24px 30px;
}
@media (min-width: 1024px) {
.DownloadLink_frame__1YEoh {
    flex-direction: row
}
  }
.DownloadLink_title__1GPwa {
  font-weight: 500;
  font-weight: var(--font-weight-medium);
  margin-bottom: 1.75rem;
  margin-bottom: var(--margin-p);
}
@media (min-width: 1024px) {
.DownloadLink_title__1GPwa {
    margin-bottom: 0
}
  }
.DownloadLink_iconWrapper__3FzJk {
  position: relative;
  margin-right: .5em;
  display: flex;
  margin-top: -3px;
}
.DownloadLink_extension__2RFG5 {
  position: absolute;
  margin: 0 !important;
  top: 5px;
  left: 4px;
  font-size: 6px;
}

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.ImageGallery_ImageGallery__DncjJ {
  padding: 50px 0;
  padding: var(--padding-section-xs) 0;
}
@media (min-width: 768px) {
.ImageGallery_ImageGallery__DncjJ {
    padding: 75px 0;
    padding: var(--padding-section-md) 0
}
  }
.ImageGallery_figure__1_5Vp {
  margin-bottom: calc(100vh / 14);
  margin-bottom: var(--grid-row);
}
.ImageGallery_figure__1_5Vp figcaption {
    margin-top: 1.75rem;
    margin-top: var(--margin-p);
  }
.ImageGallery_image__Qhog8 {
  border-radius: 10px;
  border-radius: var(--border-radius);
}

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.Story_Story__2vIcS {
  overflow: hidden;
  padding: 50px 0;
  padding: var(--padding-section-xs) 0;
}
@media (min-width: 768px) {
.Story_Story__2vIcS {
    padding: 75px 0;
    padding: var(--padding-section-md) 0
}
  }
.Story_media__1hueT {
  border-radius: 10px;
  border-radius: var(--border-radius);
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
.Story_media__1hueT {
    margin-bottom: 0
}
  }
.Story_map__1Rkyr {
  height: 0;
  padding-bottom: 65%;
}
.Story_content__GpTm3 > *:last-child {
      margin-bottom: 0;
    }

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.DescriptionList_DescriptionList__3p2nC {
  margin: 2rem 0;
}
@media (min-width: 1024px) {
.DescriptionList_DescriptionList__3p2nC {
    -webkit-column-count: 2;
            column-count: 2
}
  }
.DescriptionList_BulletList__3qqxW {
  margin: 2rem 0;
}

.DescriptionListItem_DescriptionListItem__19b6n {
  display: block;
  margin: 0 0 var(--margin-p) 0;
  padding-right: var(--margin-p);
  font-family: var(--font-family-gotham);
}

  .DescriptionListItem_DescriptionListItem__19b6n dt {
    text-transform: uppercase;
    font-size: var(--font-size-header-xs);
    font-weight: var(--font-weight-bold);
    line-height: var(--font-lineheight-headers);
    margin-bottom: .5em;
  }

  .DescriptionListItem_DescriptionListItem__19b6n dd {
    margin: 0;
    font-size: var(--font-size-body);
    font-weight: var(--font-weight-medium);
  }

.DescriptionListItem_ListItem__tG7GY {
  position: relative;
  list-style-type: none;
}

.DescriptionListItem_ListItem__tG7GY:before {
    content: '';
    position: absolute;
    top: .6rem;
    left: -1rem;
    width: 7px;
    height: 7px;
    background-color: currentColor;
  }

.DescriptionListItem_listItemTitle__1MGqB {
  display: block;
  font-weight: var(--font-weight-bold);
}

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.Person_Person__1oOnS {
  display: flex;
  margin: 2rem 0;
}
.Person_Person__1oOnS figure {
    max-width: 33%;
    margin: 0 calc(1.25rem * 2) 0 0;
    margin: 0 var(--grid-gutter-2) 0 0;
  }
@media (min-width: 768px) {
.Person_Person__1oOnS figure {
      max-width: 6.5rem
  }
    }
@media (min-width: 1024px) {
.Person_Person__1oOnS figure {
      max-width: 9.5rem
  }
    }
.Person_PersonMediaRight__10Sfn {
}
@media (min-width: 768px) {
.Person_PersonMediaRight__10Sfn {
    flex-direction: row-reverse
}

    .Person_PersonMediaRight__10Sfn .Person_details__31r9G {
      align-items: flex-end;
    }

    .Person_PersonMediaRight__10Sfn figure {
      margin: 0 0 0 calc(1.25rem * 2);
      margin: 0 0 0 var(--grid-gutter-2);
    }
  }
.Person_image__2gS34 {
  border-radius: 10px;
  border-radius: var(--border-radius);
  max-width: 100%;
}
.Person_details__31r9G {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  font-size: .875rem;
  font-size: var(--font-size-small);
}
@media (min-width: 768px) {
.Person_details__31r9G {
    font-size: 16px;
    font-size: var(--font-size-body)
}
  }
.Person_details__31r9G a {
    color: inherit;
  }
.Person_name__1zjrH {
  text-transform: uppercase;
  font-weight: 700;
  font-weight: var(--font-weight-bold);
}

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.GrayBackgroundFollows_GrayBackgroundFollows__3prm0 {
  width: 100%;
  height: 0;
}
.GrayBackgroundFollows_GrayBackgroundFollows__3prm0 ~ section {
    background-color: rgba(34,55,70,0.05098);
    background-color: var(--color-blue-dark-5);
  }
.GrayBackgroundFollows_GrayBackgroundFollows__3prm0 + section {
    padding-top: 75px !important;
    padding-top: var(--padding-section-first-top-xs) !important;
  }
@media (min-width: 768px) {
.GrayBackgroundFollows_GrayBackgroundFollows__3prm0 + section {
      padding-top: 100px !important;
      padding-top: var(--padding-section-first-top-md) !important
  }
    }

/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: rgba(255,255,255,0.90196);

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: rgba(34,55,70,0.05098);
  --color-blue-dark-10: rgba(34,55,70,0.10196);
  --color-blue-dark-40: rgba(34,55,70,0.4);
  --color-blue-dark-60: rgba(34,55,70,0.6);

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: rgba(207,223,235,0.50196);
  --color-border-dark: #D8D8D8;
  --color-label: rgba(207,223,235,0.8);

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
.Hero_Hero__2n876 {
  position: relative;
  max-width: 100%;
  height: 100vh;
  background-color: #223746;
  background-color: var(--color-blue-dark);
  overflow: hidden;
}
.Hero_background__23Pmz {
  position: relative;
  width: 100%;
  height: 100vh;
}
.Hero_background__23Pmz:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(34,55,70,0.4);
    background-color: var(--color-blue-dark-40);
    z-index: 0;
  }
.Hero_image__2AbVN {
  height: 100vh;
  object-fit: cover;
}
.Hero_video__yYQwJ {
  height: 100vh;
  padding-bottom: 0;
}
.Hero_content__G3XUD {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  color: var(--color-white);
}
.Hero_title__3bEhk {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
.Hero_title__3bEhk {
    margin-bottom: 1.75rem
}
  }
.Hero_description__241Ky {
  margin: 1rem 0 2rem 0;
}
@media (min-width: 768px) {
.Hero_description__241Ky {
    margin: 50px 0 50px 0
}
  }
.Hero_ctas__3Ip0d {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 321px) {
.Hero_ctas__3Ip0d {
    flex-direction: row
}
  }
.Hero_ctas__3Ip0d button {
    min-width: 150px;
    justify-content: center;
  }
@media (max-width: 320px) {
.Hero_ctas__3Ip0d button {
      margin-right: 0 !important
  }
    }
.Hero_buttonScroll__tTvrE {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 3rem;
  height: 3rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
@-webkit-keyframes Hero_hover__2js6a {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  30% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  60% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes Hero_hover__2js6a {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  30% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  60% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.Hero_iconNavDown__1WPfR {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-animation: Hero_hover__2js6a 2s forwards infinite;
          animation: Hero_hover__2js6a 2s forwards infinite;
  -webkit-animation-timing-function: cubic-bezier(.5, 0, .5, 1);
          animation-timing-function: cubic-bezier(.5, 0, .5, 1);
  -webkit-animation-timing-function: var(--cubic-ease-in-out);
          animation-timing-function: var(--cubic-ease-in-out);
}

