@import "../../styles/variables.css";

.PropertyTags {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--color-graphite);
  opacity: .9;
  width: 100%;
}

.platform {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &:not(:last-of-type) {
    margin: 0 0 1rem 0;
  }

  svg {
    width: 15px;
    margin-right: .5rem;
    transform: translateY(-2px);
  }

  span {
    font-family: var(--font-family-gotham);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-regular);
    line-height: var(--font-lineheight-headers);

    &[class^="dot"] {
      display: flex;
      width: 15px;
      min-width: 15px;
      height: 15px;
      min-height: 15px;
      border-radius: 100%;
      margin: 0 .5rem 0 0;

      &[class*="Development"] {
        background-color: var(--color-yellow);
      }
      &[class*="Asset-under management"] {
        background-color: var(--color-green);
      }
      &[class*="Pipeline"] {
        background-color: var(--color-purple);
      }
      &[class*="Ready for hand-over to AM"] {
        background-color: var(--color-blue);
      }
      &[class*="Exited"] {
        background-color: var(--color-gray-dark);
      }
    }
  }
}
