@import "../../styles/variables.css";

.DescriptionList {
  margin: 2rem 0;

  @media (--mq-md) {
    column-count: 2;
  }
}

.BulletList {
  margin: 2rem 0;
}
