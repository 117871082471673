@import "../../styles/variables.css";

.DataList {
  font-family: var(--font-family-gotham);
  padding: var(--padding-section-xs) 0;

  @media (--mq-sm) {
    padding: var(--padding-section-md) 0;
  }
}

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: var(--color-white);
  border: var(--border-dark);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;

  @media (--mq-md) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
