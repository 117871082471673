@import "../../styles/variables.css";

.ImageGalleryOverlay {
  z-index: var(--z-index-modal);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--color-blue-dark);
  color: var(--color-white);
  padding: var(--grid-row-2) var(--grid-gutter) var(--grid-row-2) var(--grid-gutter);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (--mq-md) {
    padding: var(--grid-row-2) var(--grid-column-2) 0 var(--grid-column-2);
  }
}

.button {
  position: fixed;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-blue-dark);
  border-radius: 100%;
  background-color: var(--color-white);
  opacity: .85;
  transition: opacity 1s;

  @media (--mq-md) {
    width: 3.5rem;
    height: 3.5rem;
  }
}

.buttonClose {
  composes: button;
  top: var(--grid-row);
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: var(--color-white);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-blue-dark-10);
    border-radius: 100%;
  }
}

.buttonNav {
  composes: button;
  display: none;

  @media (--mq-mouse-pointer-devices) {
    display: flex;
  }
}

.buttonLeft {
  composes: buttonNav;
  top: calc(50% + var(--grid-row));
  left: var(--grid-column);
  transform: translate(-50%, -50%);
}

.buttonRight {
  composes: buttonNav;
  top: calc(50% + var(--grid-row));
  right: var(--grid-column);
  transform: translate(50%, -50%);
}

.dots {
  position: fixed;
  bottom: var(--grid-row);
  left: 50%;
  display: flex;
  transform-origin: 50% 100%;
  transform: translateX(-50%);

  @media (--mq-md) {
    bottom: var(--grid-row);
  }
}


.imagesContainer {
  display: block;
  width: 100%;
  max-width: 100%;
  height: calc(100vh - var(--grid-row-2));
}

.scroller {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: calc(100vh - var(--grid-row-2));
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding-bottom: var(--grid-row-2);
  top: 0;
  left: 0;
  width: 100%;
  min-height: calc(100vh - var(--grid-row-2));
  transition: opacity .2s;

  @media (--mq-mouse-pointer-devices) {
    transition: opacity .3s var(--cubic-ease-in-out);
  }
}

.figure {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  figcaption {
    margin: 1rem 0 0 0;
    padding-bottom: 2rem;
    max-width: 100%;

    @media (--mq-md) {
      max-width: 40%;
      margin-right: 60%;
    }
  }
}

.image {
  border-radius: var(--border-radius);
  max-height: calc(10 * var(--grid-row));
  width: auto;
}
