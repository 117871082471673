@import "../../styles/variables.css";

.DataListItem {
  display: flex;
  padding: 2rem 2rem 2rem 3rem;

  @media (--mq-sm-down) {
    &:not(:last-of-type) {
      border-bottom: var(--border-dark);
    }
  }

  @media (--mq-md) {
    padding: 2rem 2rem 2rem 80px;
    border-bottom: var(--border-dark);

    &:nth-last-child(1),
    &:nth-last-child(2),
    &:nth-last-child(3) {
      border-bottom: none;
    }

    &:nth-of-type(3n-1),
    &:nth-of-type(3n-2) {
      border-right: var(--border-dark);
    }
  }

  dl {
    margin: 0;
  }

  dt {
    font-size: var(--font-size-small);
    text-transform: uppercase;
    line-height: var(--font-lineheight-headers);
    margin-bottom: .5em;
  }

  dd {
    margin: 0;
    font-size: 1.75rem;
    font-weight: var(--font-weight-bold);
    line-height: 1em;

    @media (--mq-md) {
      font-size: 2.625rem;
    }

    small {
      display: inline;
      font-size: 1.25rem;

      @media (--mq-md) {
        font-size: 2.25rem;
      }
    }
  }

}
