@import "../../styles/variables.css";

.PropertyContacts {
  width: 100%;
  background-color: var(--color-yellow);
  padding: calc(var(--grid-row) - 2rem) 0 var(--grid-row) 0;
}

.contact {
  margin-top: 2rem;

  > * {
    display: block;
    color: var(--color-blue-dark);
  }

  h6 {
    margin-bottom: .5rem;
  }
}