@import "../../styles/variables.css";

.sectionKeyFigures {
  background-color: var(--color-blue-dark-10);
  padding: var(--grid-row) 0;
}

.PropertyKeyFigures {
  width: 100%;
  border-top: var(--border-light);
  border-left: var(--border-light);
  border-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (--mq-sm) {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
  }

  @media (--mq-lg) {
    grid-template-columns: 33.3333% 33.3333% 33.3333%;
    grid-template-rows: auto;
  }
}

.logosSustain {
  display: flex;
  justify-items: flex-start;
  max-width: 200px;
  margin-bottom: 2rem;

  > div {
    max-height: 5rem;

    img {
      width: auto;
      height: 100%;
    }
  }
}

.desc {
  margin-bottom: 2rem;
}

.status {
  font-weight: var(--font-weight-regular);
  display: flex;
  align-items: flex-start;
  line-height: var(--font-lineheight-headers);

  > span {
    &[class^="dot"] {
      display: flex;
      width: 9px;
      min-width: 9px;
      height: 9px;
      min-height: 9px;
      border-radius: 100%;
      margin: .15rem .5rem 0 0;
    }

    &[class*="Development"] {
      background-color: var(--color-yellow);
    }
    &[class*="Asset-under management"] {
      background-color: var(--color-green);
    }
    &[class*="Pipeline"] {
      background-color: var(--color-purple);
    }
    &[class*="Ready for hand-over to AM"] {
      background-color: var(--color-blue);
    }
    &[class*="Exited"] {
      background-color: var(--color-gray-dark);
    }
  }
}

.statusLabel {
  line-height: var(--font-lineheight-headers);
  color: var(--color-gray-dark);
  font-size: var(--font-size-small);
}
