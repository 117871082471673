@import "../../styles/variables.css";

.PropertyKeyFiguresItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem 1.5rem;
  width: 100%;
  min-height: 71px;
  margin: 0;
  font-family: var(--font-family-gotham);
  border-bottom: var(--border-light);
  border-right: var(--border-light);
  border-color: white;

  dt {
    text-transform: uppercase;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
    line-height: var(--font-lineheight-headers);
    white-space: nowrap;
  }

  dd {
    margin: .5rem 0 0 0;
    font-size: var(--font-size-header-md);
    font-weight: var(--font-weight-bold);
  }
}
