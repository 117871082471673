@import "../../styles/variables.css";

.Person {
  display: flex;
  margin: 2rem 0;

  figure {
    max-width: 33%;
    margin: 0 var(--grid-gutter-2) 0 0;

    @media (--mq-sm) {
      max-width: 6.5rem;
    }

    @media (--mq-md) {
      max-width: 9.5rem;
    }
  }
}

.PersonMediaRight {
  composes: Person;

  @media (--mq-sm) {
    flex-direction: row-reverse;

    .details {
      align-items: flex-end;
    }

    figure {
      margin: 0 0 0 var(--grid-gutter-2);
    }
  }
}

.image {
  border-radius: var(--border-radius);
  max-width: 100%;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  font-size: var(--font-size-small);

  @media (--mq-sm) {
    font-size: var(--font-size-body);
  }

  a {
    color: inherit;
  }
}

.name {
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
}
