@import "../../styles/variables.css";

.PropertyFeatured {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity .5s var(--cubic-ease-in-out);

  @media (--mq-md) {
    height: 100%;
  }

  [class*="container"],
  [class*="row"],
  [class*="col-"] {
    @media (--mq-md) {
      height: 100%;
    }
  }

  [class*="container"],
  [class*="col-"] {
    position: relative;
  }

  [class*="container"],
  [class*="row"] {
    pointer-events: none;
  }

  [class*="col-"] {
    pointer-events: auto;
  }
}

.imageLink {
  display: block;
  position: relative;
  transition: transform .5s var(--cubic-ease-in-out);

  @media (--mq-md) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:hover {
      transform: scale(1.02) !important;
    }
  }
}

.image {
  display: block;
  position: relative;
  width: 100%;
  height: 56.25vw;
  object-fit: cover;

  @media (--mq-md) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


@keyframes imageEnterPrevIn {
  0% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes imageEnterPrevOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(10%);
  }
}

@keyframes imageEnterNextIn {
  0% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes imageEnterNextOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10%);
  }
}

.imagePrevIn {
  composes: image;
  transform: translateX(0);
  animation: imageEnterPrevIn .5s forwards var(--cubic-ease-in-out);
}
.imagePrevOut {
  composes: image;
  transform: translateX(0);
  animation: imageEnterPrevOut .5s forwards var(--cubic-ease-in-out);
}

.imageNextIn {
  composes: image;
  transform: translateX(0);
  animation: imageEnterNextIn .5s forwards var(--cubic-ease-in-out);
}
.imageNextOut {
  composes: image;
  transform: translateX(0);
  animation: imageEnterNextOut .5s forwards var(--cubic-ease-in-out);
}

.background {
  display: none;

  @media (--mq-md) {
    z-index: 0;
    display: block;
    position: absolute;
    top: 0;
    left: calc(-1 * var(--grid-column-half));
    width: calc(100% + var(--grid-column));
    height: 100%;
    background-color: var(--color-white);
    opacity: .9;
  }
}

.content {
  z-index: 1;
  position: relative;
  padding: var(--grid-row) 0;

  @media (--mq-md) {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    > * {
      margin-bottom: var(--grid-row-half);
    }
  }
}

.logoPlatform {
  height: 2.5rem;
  width: auto;

  @media (--mq-sm-down) {
    margin-bottom: 2rem;
  }
}

.tags {
  margin-bottom: var(--grid-row-half);
}

.description {
  @media (--mq-md) {
    max-height: 25rem;
    overflow: hidden;
  }
}
