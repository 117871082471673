@import "../../styles/variables.css";

.PropertiesMapFilterList {
  padding: 0;
  margin: 0 0 2rem 0;
  list-style-type: none;

  li {
    padding: .25rem 0;
  }

  [class="checkmark"] {
    &:after {
      border-color: var(--color-white) !important;
    }
  }

  [class="checklabel"] {
    font-weight: var(--font-weight-regular) !important;
  }
}

.listTitle {
  margin-bottom: 1em;
}
