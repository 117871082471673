.DescriptionListItem {
  display: block;
  margin: 0 0 var(--margin-p) 0;
  padding-right: var(--margin-p);
  font-family: var(--font-family-gotham);

  dt {
    text-transform: uppercase;
    font-size: var(--font-size-header-xs);
    font-weight: var(--font-weight-bold);
    line-height: var(--font-lineheight-headers);
    margin-bottom: .5em;
  }

  dd {
    margin: 0;
    font-size: var(--font-size-body);
    font-weight: var(--font-weight-medium);
  }
}

.ListItem {
  position: relative;
  list-style-type: none;

  &:before {
    content: '';
    position: absolute;
    top: .6rem;
    left: -1rem;
    width: 7px;
    height: 7px;
    background-color: currentColor;
  }
}

.listItemTitle {
  display: block;
  font-weight: var(--font-weight-bold);
}
