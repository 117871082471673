@import "../../styles/variables.css";

.VimeoPlayer {
  position: relative;
  display: flex;
  padding-bottom: 56.25%;
  width: 100%;
  height: 0;
  overflow: hidden;
  background-color: var(--color-black);
  transition: opacity .75s, transform .75s;
}

.VimeoPlayerBackground {
  composes: VimeoPlayer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  z-index: 0 !important;
  color: var(--color-white);
}

.frame {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 1s;
}

.videoContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.videoPlayer {
  width: 100%;
  height: 100%;
  transition: opacity .5s;
}

.buttonPlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-blue-dark-60);
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    .iconPlay {
      transform: scale(.77);
    }
  }
}

.iconPlay {
  opacity: .9;
  box-shadow: var(--box-shadow);
  transform: scale(.7);
  transition: transform .1s var(--cubic-ease-in-out);
}
