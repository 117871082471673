@import "../../styles/variables.css";

.Hero {
  position: relative;
  max-width: 100%;
  height: 100vh;
  background-color: var(--color-blue-dark);
  overflow: hidden;
}

.background {
  position: relative;
  width: 100%;
  height: 100vh;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-blue-dark-40);
    z-index: 0;
  }
}

.image {
  height: 100vh;
  object-fit: cover;
}

.video {
  height: 100vh;
  padding-bottom: 0;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
}

.title {
  margin-bottom: 1rem;

  @media (--mq-sm) {
    margin-bottom: 1.75rem;
  }
}

.description {
  margin: 1rem 0 2rem 0;

  @media (--mq-sm) {
    margin: 50px 0 50px 0;
  }
}

.ctas {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (--mq-xs) {
    flex-direction: row;
  }

  button {
    min-width: 150px;
    justify-content: center;

    @media (--mq-xxs-down) {
      margin-right: 0 !important;
    }
  }
}

.buttonScroll {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  width: 3rem;
  height: 3rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}


@keyframes hover {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(10px);
  }
  60% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

.iconNavDown {
  transform: translateY(0);
  animation: hover 2s forwards infinite;
  animation-timing-function: var(--cubic-ease-in-out);
}
