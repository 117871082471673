@import "../../styles/variables.css";

.figure {
  max-width: 100%;
  margin: 0;
}

.button {
  display: block;
  width: 100%;
  overflow: hidden;
  border-radius: var(--border-radius);

  img {
    transition: transform 1s;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}
