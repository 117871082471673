@import "../../styles/variables.css";

.TitleDescription {
  position: relative;
  padding: var(--padding-section-xs) 0;

  @media (--mq-sm) {
    padding: var(--padding-section-md) 0;
  }
}

.title {
  margin: 0;
  overflow: hidden;
}

.description {
  margin-bottom: calc(-1 * var(--margin-p));
  margin-top: 25px;

  @media (--mq-sm) {
    margin-top: 50px;
  }
}

