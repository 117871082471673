@import "flexboxgrid.css";
@import "variables.css";
@import "grid-xl.css";
@import "grid-xxl.css";

/* flexboxgrid overrides */
.container-fluid {
  position: relative;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;

  @media (--mq-sm) {
    width: var(--content-max-width);
    padding-left: var(--grid-gutter);
    padding-right: var(--grid-gutter);
  }
}

/* WITH GUTTER */
.row {
  margin-right: -1rem;
  margin-left: -1rem;

  @media (--mq-sm) {
    margin-right: calc(-1 * var(--grid-gutter));
    margin-left: calc(-1 * var(--grid-gutter));
  }
}

[class*="col-"] {
  padding-right: 1rem;
  padding-left: 1rem;

  @media (--mq-sm) {
    padding-right: var(--grid-gutter);
    padding-left: var(--grid-gutter);
  }
}
