@import "../../styles/variables.css";

.RelatedItem {
  margin-bottom: var(--grid-row-2);

  @media (--mq-sm) {
    margin-bottom: 0;
  }
}

.image {
  margin-bottom: var(--grid-row);
  border-radius: var(--border-radius);

  @media (--mq-sm) {
    margin-bottom: var(--grid-row-half);
  }
}
