@import "../../styles/variables.css";

@keyframes progress {
  0% {
    stroke-dashoffset: 100;
  }
  50% {
    stroke-dashoffset: 68;
  }
  51% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -32;
  }
}

.LoaderSpinner {
  z-index: 1000;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  *, *:before, *:after {
    box-sizing: border-box;
  }
}

.svg {
  transform: rotate(-90deg);
  fill: none;
  stroke: currentColor;
}

.progress {
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: progress 1.5s forwards infinite;
  animation-timing-function: linear;
}
