@import "variables.css";

html, body {
  font-family: var(--font-family-lato);
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-regular);
  line-height: var(--font-lineheight-body);
  letter-spacing: .02em;
  background-color: var(--color-white);
  color: var(--color-blue-dark);
}

* {
  /*
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  */
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}


/* Headers */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0 0 1.25em 0;
  line-height: 1.2em;
  font-family: var(--font-family-gotham);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  overflow-wrap: normal;
  word-wrap: normal;
  hyphens: none;
}

h1,
.h1 {
  font-size: var(--font-size-header-xl);
}
h2,
.h2 {
  font-size: var(--font-size-header-lg);
}
h3,
.h3 {
  font-size: var(--font-size-header-md);
}
h4,
.h4 {
  font-size: var(--font-size-header-sm);
}
h5,
.h5 {
  font-size: var(--font-size-header-xs);
}
h6,
.h6 {
  font-size: var(--font-size-body);
  text-transform: capitalize;
}


p {
  font-family: var(--font-family-lato);
  margin-top: 0;
  margin-bottom: var(--margin-p);
}

b,
strong {
  font-weight: var(--font-weight-bold);
}

small {
  display: block;
  font-size: var(--font-size-small);
  line-height: var(--font-lineheight-body);

  * {
    font-size: var(--font-size-small);
    line-height: var(--font-lineheight-body);
  }
}

a {
  color: var(--color-blue);
  font-weight: inherit;
  text-underline-offset: 2px;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: .5px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.button {
    &:hover {
      text-decoration: none;
    }
  }
}

ul, ol {
  margin-top: 0;
  padding-left: 1em;
}

address {
  font-style: normal;
}

hr {
  border: none;
  background-color: currentColor;
  color: currentColor;
  height: 1px;
  margin: 2rem 0;
  opacity: .2;
}
