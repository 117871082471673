@import "../../styles/variables.css";

.InfoWindow {
  display: flex;
  justify-content: space-between;
  width: 250px;

  @media (--mq-xs-down) {
  }
}

.InfoWindowImage {
  composes: InfoWindow;
  width: 300px;

  @media (--mq-xs-down) {
    width: calc(100vw - 2rem);
  }

  .content {
    width: 60%;
  }
}

.image {
  width: 40%;
  min-height: 100%;
  object-fit: cover;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 7px 12px 12px;
  font-family: var(--font-family-gotham);
  font-size: var(--font-size-small);
  line-height: var(--font-lineheight-headers);

  > span {
    padding: .15rem 0;

    &:first-of-type {
      padding-right: 1.5rem;
      font-weight: var(--font-weight-medium);
    }
  }

  > button {
    justify-content: center;
    margin: 15px 0 0 0;
  }
}

.segment {
  font-weight: var(--font-weight-regular);
}

.status {
  font-weight: var(--font-weight-regular);
  display: flex;
  align-items: center;
  white-space: nowrap;

  > span {
    &[class^="dot"] {
      display: flex;
      width: 9px;
      min-width: 9px;
      height: 9px;
      min-height: 9px;
      border-radius: 100%;
      margin-right: .5rem;
      background-color: var(--color-black);

      &[class*="Development"] {
        background-color: var(--color-yellow);
      }
      &[class*="Asset-under management"] {
        background-color: var(--color-green);
      }
      &[class*="Pipeline"] {
        background-color: var(--color-purple);
      }
      &[class*="Ready for hand-over to AM"] {
        background-color: var(--color-blue);
      }
      &[class*="Exited"] {
        background-color: var(--color-gray-dark);
      }
    }
  }
}

.label {
  white-space: nowrap;
  color: var(--color-gray-dark);
  max-width: calc(100% - 1rem);
  overflow: hidden;
}

[class="gm-style-iw gm-style-iw-c"] {
  padding: 0 !important;
  max-width: none !important;
  max-height: none !important;

  @media (--mq-xs-down) {
    width: calc(100vw - 2rem);
    min-height: 100px;
    position: fixed;
    bottom: auto;
    transform: none;
    top: 0;
    left: calc(-50% + 1rem);
  }
}

[class="gm-style-iw-d"] {
  overflow: auto !important;
  max-height: none !important;
}

[class="gm-ui-hover-effect"] {
  z-index: 1;
  opacity: 1 !important;
  top: .25rem !important;
  right: .25rem !important;
  background-color: var(--color-white) !important;
  border-radius: 100%;
  cursor: pointer;
}

[class="gm-style-iw-t"] {
  &:after {
    z-index: 0;
  }
}
