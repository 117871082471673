@import "../../styles/variables.css";

.Styleguide {
  width: 100%;

  section {
    margin: var(--grid-row) 0;
  }
}

.color {
  width: 100%;
  height: 100px;
  padding: .5rem;
  margin-bottom: 3rem;
}

.colorBlueDark {
  composes: color;
  background-color: var(--color-blue-dark);
  color: var(--color-white);
}
.colorGraphite {
  composes: color;
  background-color: var(--color-graphite);
  color: var(--color-white);
}
.colorBlack {
  composes: color;
  background-color: var(--color-black);
  color: var(--color-white);
}
.colorWhite {
  composes: color;
  background-color: var(--color-white);
  color: var(--color-blue-dark);
  border: 1px solid currentColor;
}


.colorBlue {
  composes: color;
  background-color: var(--color-blue);
  color: var(--color-white);
}
.colorGreen {
  composes: color;
  background-color: var(--color-green);
  color: var(--color-white);
}
.colorPurple {
  composes: color;
  background-color: var(--color-purple);
  color: var(--color-white);
}
.colorYellow {
  composes: color;
  background-color: var(--color-yellow);
  color: var(--color-blue-dark);
}

.icon {
  height: 100px;
}

.bgDark {
  padding: var(--grid-row) 0;
  background-color: var(--color-blue-dark);
  color: var(--color-white);
}
