@import "../../styles/variables.css";

.GrayBackgroundFollows {
  width: 100%;
  height: 0;

  & ~ section {
    background-color: var(--color-blue-dark-5);
  }

  + section {
    padding-top: var(--padding-section-first-top-xs) !important;

    @media (--mq-sm) {
      padding-top: var(--padding-section-first-top-md) !important;
    }
  }
}
