@import "../../styles/variables.css";

.PropertiesFeatured {

}

.scrollContainer {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.properties {
  width: 100%;
  display: block;
  transition: transform .45s var(--cubic-ease-in-out);

  @media (--mq-md) {
    display: flex;
    height: calc(100vh - 193px);
    transition: transform .75s var(--cubic-ease-in-out);
  }
}

.dots {
  position: absolute;
  z-index: 1;
  top: 60vw;
  left: 50%;
  display: flex;
  transform: translateX(-50%);

  @media (--mq-md) {
    transform: translateX(0);
    top: auto;
    bottom: calc(var(--grid-row-half) + 15px);
    left: calc(var(--grid-column-half) + var(--grid-gutter));
  }
}

.navigation {
  z-index: 1;
  position: absolute;
  top: 53vw;
  right: 16px;
  transform-origin: 100% 100%;
  transform: translateY(-100%);
  display: flex;
  padding: 4px;

  @media (--mq-md) {
    transform: translateY(0);
    top: auto;
    bottom: var(--grid-row-half);
    right: auto;
    left: calc(7 * var(--grid-column-half));
  }

  @media (--mq-xl) {
    left: calc(6 * var(--grid-column-half));
  }

  @media (--mq-xxl) {
    left: calc(4.5 * var(--grid-column-half));
  }
}

.navButton {
  z-index: 2;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: var(--color-white);
  color: var(--color-blue-dark);
  box-shadow: var(--box-shadow);
  display: flex;
  justify-content: center;
  align-items: center;

  @media (--mq-md) {
    background-color: var(--color-blue-dark);
    color: var(--color-white);
  }

  &:first-of-type {
    margin-right: 15px;

    svg {
      margin-right: 2px;
    }
  }

  &:last-of-type {
    svg {
      margin-left: 2px;
    }
  }

  * {
    pointer-events: none;
  }
}
