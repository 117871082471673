@import "../../styles/variables.css";

.ImageGallery {
  padding: var(--padding-section-xs) 0;

  @media (--mq-sm) {
    padding: var(--padding-section-md) 0;
  }
}

.figure {
  margin-bottom: var(--grid-row);

  figcaption {
    margin-top: var(--margin-p);
  }
}

.image {
  border-radius: var(--border-radius);
}
