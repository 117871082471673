@import "../../styles/variables.css";

.RelatedItems {
  background-color: var(--color-blue-dark);
  color: var(--color-white);
  padding-bottom: var(--grid-row);

  @media (--mq-sm) {
    padding-bottom: var(--grid-row-2);
  }
}

.titleDescription {
  background-color: var(--color-blue-dark);
  padding: 50px 0 calc(50px + var(--margin-p)) 0;
  margin: 0 !important;

  @media (--mq-sm) {
    padding: 100px 0 calc(100px + var(--margin-p)) 0;
  }
}

.header {
  padding-bottom: var(--grid-row);
}
