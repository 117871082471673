/* Define Custom Media queries */
@custom-media --mq-touch-devices (hover: none) and (pointer: coarse);
@custom-media --mq-mouse-pointer-devices (hover: hover) and (pointer: fine);
@custom-media --mq-xxs-down (max-width: 320px);
@custom-media --mq-xs-down (max-width: 767px);
@custom-media --mq-sm-down (max-width: 1023px);
@custom-media --mq-xs (min-width: 321px);
@custom-media --mq-sm (min-width: 768px);
@custom-media --mq-md (min-width: 1024px);
@custom-media --mq-lg (min-width: 1200px);
@custom-media --mq-xl (min-width: 1440px);
@custom-media --mq-xxl (min-width: 1920px);

/* Define global CSS variables inside :root */
:root {
  --grid-column-half: calc(100vw / 28);
  --grid-column: calc(100vw / 14);
  --grid-column-2: calc(100vw / 7);
  --grid-column-3: calc((100vw / 14) * 3);
  --grid-row-half: calc(var(--grid-row) * .5);
  --grid-row: calc(100vh / 14);
  --grid-row-2: calc(100vh / 7);
  --content-max-width: calc(var(--grid-column) * 13);
  --grid-gutter: 1.25rem;
  --grid-gutter-2: calc(var(--grid-gutter) * 2);

  --margin-p: 1.75rem;

  --padding-section-xs: 50px;
  --padding-section-md: 75px;
  --padding-section-first-top-xs: 75px;
  --padding-section-first-top-md: 100px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-90: #ffffffE6;

  --color-graphite: #2C3033;
  --color-gray-dark: #575757;
  --color-gray: #7C7C79;
  --color-gray-light: #D8D8D8;
  --color-blue-dark: #223746;
  --color-blue-dark-5: #2237460D;
  --color-blue-dark-10: #2237461A;
  --color-blue-dark-40: #22374666;
  --color-blue-dark-60: #22374699;

  --color-blue: #00A0D2;
  --color-green: #00AF69;
  --color-purple: #55325F;
  --color-yellow: #F0BE41;


  --color-border: #CFDFEB80;
  --color-border-dark: #D8D8D8;
  --color-label: #CFDFEBCC;

  --font-family-gotham: 'Gotham', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-lato: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-smaller: .5rem;
  --font-size-small: .875rem;
  --font-size-body: 16px;
  --font-size-header-xs: 1.125rem;
  --font-size-header-sm: 1.25rem;
  --font-size-header-md: 1.5rem;
  --font-size-header-lg: 1.75rem;
  --font-size-header-xl: 2.25rem;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-lineheight-body: 1.625em;
  --font-lineheight-headers: 1.2em;

  --border-light: 1px solid var(--color-border);
  --border: 2px solid var(--color-border);
  --border-dark: 2px solid var(--color-border-dark);
  --border-radius: 10px;

  --box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  --box-shadow-transparent: 0 0 10px rgba(0, 0, 0, 0);

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);
  --cubic-ease-in: cubic-bezier(.5, 0, 1, .5);

  --z-index-modal: 102;
}
