@import "../../styles/variables.css";

.DownloadLink {
  font-family: var(--font-family-gotham);
  padding: var(--padding-section-xs) 0;

  @media (--mq-sm) {
    padding: var(--padding-section-md) 0;
  }
}

.frame {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: var(--border-dark);
  border-radius: var(--border-radius);
  background-color: var(--color-white);
  padding: 27px 44px 24px 30px;

  @media (--mq-md) {
    flex-direction: row;
  }
}

.title {
  font-weight: var(--font-weight-medium);
  margin-bottom: var(--margin-p);

  @media (--mq-md) {
    margin-bottom: 0;
  }
}

.iconWrapper {
  position: relative;
  margin-right: .5em;
  display: flex;
  margin-top: -3px;
}

.extension {
  position: absolute;
  margin: 0 !important;
  top: 5px;
  left: 4px;
  font-size: 6px;
}
