@import "../../styles/variables.css";

.PropertiesMap {
}

.mapContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.map {
  width: 100%;
  height: 100%;
}

.clusterIcon {
  background: var(--color-blue-dark);
  color: var(--color-white);
  border-radius: 100%;
  border: 2px solid var(--color-white);
  font-family: var(--font-family-gotham);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  box-shadow: var(--box-shadow);
}
